import React from 'react';

class CustomIntegerInput extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 0
    };
  }

  handleChange = (event) => {
    const {icon} = this.props;
    let newValue = parseInt(event.target.value, 10);

    // Enforce maximum value if the icon is '%'
    if (icon === '%' && newValue > 100) {
      newValue = 100;
    }

    this.setState({ value: newValue });
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  };

  render() {
    const { label, toolTip, icon, value, onChange } = this.props;

    return (
      <div className='RT-TextBox'>
        <label>
          {label}
          {toolTip && <span className="tooltip">{toolTip}</span>}
        </label>
        <div className="input-group" style={{ width: 250, height: 35 }}>
          {icon && <span className="input-group-text">{icon}</span>}
          <input
            type="number"
            className="form-control"
            value={this.state.value}
            onChange={this.handleChange}
            min="0"
          />
        </div>
      </div>
    );
  }
}

export default CustomIntegerInput;