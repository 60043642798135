import React from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  Confirm,
  RecordHeader,
} from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Button, DataGrid, IDataGridLayout } from "ktm-ui";
import RecordScreen from "./Record";
import NewScreen from "./New";
import AdvisorNewScreen from "enroll/advisor/New";

interface IdsResults {
  Id: string;
  Name: string;
  PolicyNo: string;
  PolicyHolder_Id: string;
  PrimaryAdvisor_Id: string;
  EnrollmentStatus: string;
  EnrollmentNote: string;
  PolicyHolder: string;
  Advisor: string;
  CreatedAt: string;
}

export default KTMScreen(
  { name: "active.policy.browse" },
  class extends React.Component<{}> {
    data: {
      dsResults: IdsResults;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsResults";
      await KTMApi.start(screen, {});
      screen.title = "Companies";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.pending_enrollments)}
        </Layout>
      );
    }

    header() {
      return <RecordHeader label="Companies" />;
    }

    pending_enrollments({ field, draw, action, collection }: RenderEvent) {
      let actions = (
        <>
          {/* <Button
            action={action(this.new_enrollment)}
            label="Start new enrollment"
            style="add"
          />
          <Button
            action={action(this.enroll_advisor)}
            label="Enroll advisor"
            style="add"
          /> */}
          {/* <Button
            action={action(this.run_payment)}
            label="Run Payment Process"
            style="add"
          />
          <Button
            action={action(this.run_paymentbatch)}
            label="Run Payment Batch"
            style="add"
          />
          <Button
            action={action(this.generate_payment_report)}
            label="Generate Payment Report"
            style="add"
          /> */}

          {/* <Button
            action={action(this.run_corehealth)}
            label="Run CoreHealth+"
            style="add"
          />

          <Button
            action={action(this.run_edis)}
            label="Run EDIS"
            style="add"
          /> */}
          <Button
            action={action(this.search)}
            label="Refresh list"
            style="link"
          />
        </>
      );
      let layout: IDataGridLayout = {
        columns: [
          {
            name: "PolicyNo",
            label: "Policy No",
            type: "character",
            width: 145,
          },
          {
            name: "PolicyHolder",
            label: "Policy Holder",
            type: "character",
            width: 400,
          },
          { name: "Advisor", label: "Advisor", type: "character", width: 250 },
          {
            name: "EnrollmentStatus",
            label: "Status",
            type: "character",
            width: 100,
          },
          {
            name: "EnrollmentNote",
            label: "Note",
            type: "longchar",
            width: 275,
          },
          { name: "StartDate", label: "Start Date", type: "date" },
          { name: "EndDate", label: "End Date", type: "date" },
          { name: "HasHSA", label: "HSA", type: "logical" },
          { name: "HasLSA", label: "LSA", type: "logical" },
          { name: "HasFlex", label: "Flex", type: "logical" },
          { name: "Name", label: "Name", type: "character" }
        ],
        sort: [{ name: "PolicyNo", descending: true }],
      };
      return (
        <DataGrid
          collection={collection("@dsResults")}
          layout={layout}
          actions={actions}
          onRowClick={action(this.show_record)}
        />
      );
    }

    async search({ screen }: ActionEvent) {
      await KTMApi.action(screen, "search", {});
    }

    confirmDialog() {
      return <Confirm message="Are you sure you want to refresh?" />;
    }

    async show_record({ screen }: ActionEvent) {
      await screen.browse(<RecordScreen policy_id={screen.getValue("Id")} />, {
        onRecordChanged: this.refresh_row,
        label: "Policy",
      });
    }

    async refresh_row({ screen, eventValue }: ActionEvent) {
      await KTMApi.refreshRow(screen, {
        collection: "@dsResults",
        rowid: eventValue,
      });
    }

    async new_enrollment({ screen }: ActionEvent) {
      await screen.openDialog(<NewScreen new_policy_id={null} />, {
        onRecordChanged: this.on_create_enrollment,
      });
    }

    async enroll_advisor({ screen }: ActionEvent) {
      await screen.openDialog(<AdvisorNewScreen />);
    }

    async on_create_enrollment({ screen, eventValue }: ActionEvent) {
      await screen.openOnStack(<RecordScreen policy_id={eventValue} />);
      await KTMApi.refreshRow(screen, {
        collection: "@dsResults",
        rowid: eventValue,
      });
    }

    async run_payment({ screen, eventValue }: ActionEvent) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };

      await fetch("/api/Payment/run", requestOptions);
    }

    async run_paymentbatch({ screen, eventValue }: ActionEvent) {
      const requestOptions = {
        method: "GET"
      };

      await fetch("/api/Payment/paymentbatch", requestOptions);
    }

    async generate_payment_report({ screen, eventValue }: ActionEvent) {
      // const requestOptions = {
      //     method: 'GET',
      //     headers: {'Content-Type': 'application/json'},
      //     body: JSON.stringify({
      //     })
      // }

      await fetch("/api/Payment/report")
        .then((response) => response.json())
        .then((data) => {

          let a = document.createElement("a");
          a.href = "data:application/octet-stream;base64," + data.base64;
          a.download = "paymentreport.pdf";
          a.click();
        });
    }

    async run_corehealth({ screen, eventValue }: ActionEvent) {}
    async run_edis({ screen, eventValue }: ActionEvent) {}
  }
);
