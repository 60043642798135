import React from "react";
import {KTMScreen} from "ktm-ui";
import toastr from "toastr";
import Moment from "react-moment";
import {Button, Modal, Table,} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import { MdOutlineSimCardDownload } from "react-icons/md";

export default KTMScreen(
    {name: "corehealthdashboard"},
    class extends React.Component<any, any> {

        state = {
            loading: false,
            success: false,
            searchInput: null,
            searchResult: null,
            note: null,
            chqNo: null,
            amount: 0,
            ledger: [],
            pendingInvoice: [],
            paidInvoice: [],
            showFundingModal: false,
            showReverseFundingModal: false,
            showReverseInvoiceModal: false,
            reversalAmount: 0,
            reversalNote: null,
            selectedReversalInvoice: null,
            padInvoices: []
        };

        componentDidMount() {
            this.getPendingInvoice();
            this.getPaidInvoice();
            this.getInvoicesForPad()
        }

        getPendingInvoice = () => {
            fetch('/api/corehealth/getpendinginvoice')
                .then(response => response.json())
                .then(data => this.setState({pendingInvoice: data}));
        };

        getPaidInvoice = () => {
            fetch('/api/corehealth/getpaidinvoice')
                .then(response => response.json())
                .then(data => this.setState({paidInvoice: data}));
        };

        getInvoicesForPad = () => {
            fetch('/api/corehealth/getpadinvoices')
                .then(response => response.json())
                .then(data => this.setState({padInvoices: data}));
        }

        submitSearch = () => {
            if (this.state.searchInput != null && this.state.searchInput != "") {
                fetch("/api/corehealth/searchpolicy/" + this.state.searchInput, {
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        this.setState(
                            {
                                searchResult: data,
                                loading: false,
                                success: data.success,
                                amount: 0,
                            },
                            () => {
                                if (!this.state.success) {
                                    toastr.error("Policy not found", "Error");
                                }
                            }
                        );
                    })
                    .catch((error) => {
                        this.setState({searchResult: null, success: false});
                        toastr.error("Search failed", "Error");
                    });
            }
        };

        handleCloseFundingModal = () => {
            this.setState({showFundingModal: false});
        };

        handleSubmitFundingModal = () => {
            this.submitFunding();
            this.setState({showFundingModal: false});
        };

        handleShowReverseFundingModal = (amount, note) => {
            this.setState({reversalAmount: amount, reversalNote: note, showReverseFundingModal: true});
        }

        handleCloseReverseFundingModal = () => {
            this.setState({showReverseFundingModal: false});
        };

        handleSubmitReverseFundingModal = () => {
            this.submitReversalFunding();
            this.setState({showReverseFundingModal: false});
        };

        handleShowReverseInvoiceModal = (invoice) => {
            this.setState({selectedReversalInvoice: invoice, showReverseInvoiceModal: true});
        }

        handleCloseReverseInvoiceModal = () => {
            this.setState({showReverseInvoiceModal: false});
        };

        handleSubmitReverseInvoiceModal = () => {
            this.submitReversalInvoice();
            this.setState({showReverseInvoiceModal: false});
        };

        verifyFunding = () => {
            var amountExists = false,
                currentDate = new Date();
            if (
                this.state.searchResult.ledgerEntries != null &&
                this.state.searchResult.ledgerEntries.length > 0
            ) {
                amountExists = this.state.searchResult.ledgerEntries.some((entry) => {
                    let entryDate = new Date(entry.transDate);
                    return entry.amount === Number(this.state.amount) && entryDate.getFullYear() === currentDate.getFullYear() && entryDate.getMonth() === currentDate.getMonth() && entryDate.getDate() === currentDate.getDate();
                });
                if (amountExists) {
                    this.setState({showFundingModal: true});
                } else {
                    this.submitFunding();
                }
            } else {
                this.submitFunding();
            }
        };
        submitFunding = () => {
            fetch("/api/corehealth/setonlinefunds", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    policyId: this.state.searchResult.policyId,
                    amount: this.state.amount,
                    note: this.state.note,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            searchResult: data,
                            loading: false,
                            success: data.success,
                            amount: 0,
                        },
                        () => {
                            if (!this.state.success) {
                                toastr.error("Failed to submit", "Error");
                            }
                        }
                    );
                    this.getPendingInvoice();
                })
                .catch((error) => {
                    toastr.error("Submission failed", "Error");
                });
        };

        submitReversalInvoice = () => {
            // call this approveinvoice using selectedReversalInvoice
            fetch(`/api/corehealth/reverseinvoice?invoiceId=${this.state.selectedReversalInvoice}`, {
                method: 'POST',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    this.setState({
                        selectedReversalInvoice: null,
                        showReverseInvoiceModal: false
                    }, () => {
                        this.submitSearch();
                        this.getPendingInvoice();
                    })
                    toastr.success("Invoice reversed", "Success")
                })
                .catch((error) => {
                    console.error('There has been a problem with your fetch operation:', error);
                });
        }

        submitReversalFunding = () => {
            fetch("/api/corehealth/reversefunds", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    policyId: this.state.searchResult.policyId,
                    amount: this.state.amount,
                    note: this.state.reversalNote
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            searchResult: data,
                            loading: false,
                            success: data.success,
                            amount: 0,
                            reversalNote: null,
                        },
                        () => {
                            if (!this.state.success) {
                                toastr.error("Failed to submit", "Error");
                            }
                        }
                    );
                    this.getPendingInvoice();
                })
                .catch((error) => {
                    toastr.error("Submission failed", "Error");
                });
        };

        setInput = (e) => {
            this.setState({searchInput: e.target.value});
        };
        setNote = (e) => {
            this.setState({note: e.target.value});
        };
        setChqNo = (e) => {
            this.setState({chqNo: e.target.value}, () => {
                var newNote = "Cheque " + this.state.chqNo;
                this.setState({note: newNote})
            });
        };
        setAmount = (e) => {
            this.setState({amount: e.target.value});
        };

        handleKeyDown = (event) => {
            if (event.key === "Enter") {
                this.submitSearch();
            }
        };

        downloadPayment = (id, fileNum) => {
            this.setState({loading: true});
            fetch(`/api/corehealth/downloadpaymenteft/${id}`)
                .then((response) => response.blob())
                .then((blob) => {
                    const currentDate = new Date().toISOString().slice(0, 10);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `Corehealthayment_${fileNum}_${currentDate}.txt`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    this.setState({loading: false});
                })
                .catch((error) => {
                    this.setState({loading: false});
                });
        };

        runPaymentProcess = () => {
            this.setState({loading: true});
            fetch('/api/corehealth/generateinvoicecommission', {
                method: 'POST',
            })
                .then(response => {
                    this.setState({loading: false});
                    if (response.status === 200) {
                        this.getPaidInvoice();
                        toastr.success("Invoice commission generated", "Success");
                    } else {
                        toastr.error("Failed to generate invoice commission", "Error");
                    }
                })
                .catch(error => {
                    this.setState({loading: false});
                    toastr.error("Failed to generate invoice commission", "Error");
                });
        }

        downloadPadFile = (val: string) => {
            fetch(`/api/corehealth/downloadpadfile?invoiceDate=${val}`)
                .then(response => response.json())
                .then(data => {
                    const fileName = data.fileName;
                    const fileContent = data.fileContent;

                    const byteCharacters = atob(fileContent);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);

                    const blob = new Blob([byteArray], {type: "text/plain"});
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    console.error("Error downloading the file", error);
                });
        }

        downloadPaymentFile = (val) => {
            this.setState({ loading: true });
            fetch(`/api/corehealth/downloadpaymentfile?invoiceDate=${val}`)
                .then((response) => response.blob())
                .then((blob) => {
                    const currentDate = new Date().toISOString().slice(0, 10);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `CorehealthPayment_${val}.txt`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                });
        };

        downloadCorehealthSummary = (val) => {
            this.setState({ loading: true });
            fetch(`/api/corehealth/downloadcorehealthsummary?invoiceDate=${val}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then((blob) => {
                    const currentDate = new Date().toISOString().slice(0, 10);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `CorehealthSummary_${val}.csv`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    console.error('Error while downloading CSV:', error);
                    this.setState({ loading: false });
                });
        }

        isPaymentFileEnabled = (invoiceDate) => {
            const invoiceDateObj = new Date(invoiceDate);
            const currentDate = new Date();
            return currentDate >= new Date(invoiceDateObj.setMonth(invoiceDateObj.getMonth() + 1));
        }

        render() {

            let responseContents = null;
            let ledgerTable = null;
            let invoiceTable = null;

            let fundingModal = <Modal show={this.state.showFundingModal} onHide={this.handleCloseFundingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Apply Funding</Modal.Title>
                </Modal.Header>
                <Modal.Body>There is a recent funding entry for this amount. Would you like to proceed?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseFundingModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmitFundingModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            let reverseFundingModal = <Modal show={this.state.showReverseFundingModal}
                                             onHide={this.handleCloseReverseFundingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reverse Funding</Modal.Title>
                </Modal.Header>
                <Modal.Body>This will add a reversal entry of ${this.state.reversalAmount} to the ledger. Would you like
                    to proceed?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseReverseFundingModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmitReverseFundingModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            let reverseInvoiceModal = <Modal show={this.state.showReverseInvoiceModal}
                                             onHide={this.handleCloseReverseInvoiceModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reverse Funding</Modal.Title>
                </Modal.Header>
                <Modal.Body>This will set this invoice and related commissions as unapproved and will rollback the
                    funding entry. Would you like
                    to proceed?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseReverseInvoiceModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmitReverseInvoiceModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            if (this.state.searchResult != null && this.state.success) {
                if (
                    this.state.searchResult.ledgerEntries != null &&
                    this.state.searchResult.ledgerEntries.length > 0
                ) {
                    ledgerTable = (
                        <table className="table">
                            <thead>
                            <tr>
                                <td>Type</td>
                                <td style={{textAlign: "right"}}>Amount</td>
                                <td>Date</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.searchResult.ledgerEntries.map((data, key) => {
                                return (
                                    <tr>
                                        <td>{data.note}</td>
                                        <td style={{textAlign: "right"}}>
                                            {data.amount.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </td>
                                        <td>
                                            <Moment local format="YYYY-MM-DD">
                                                {data.transDate}
                                            </Moment>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    );
                }

                if (
                    this.state.searchResult.invoiceEntries != null &&
                    this.state.searchResult.invoiceEntries.length > 0
                ) {
                    invoiceTable = (
                        <table className="table">
                            <thead>
                            <tr>
                                <td>Invoice No</td>
                                <td>Date</td>
                                <td style={{textAlign: "right"}}>Total Amount</td>
                                <td>Status</td>
                                <td>Approved</td>
                                <td>Paid</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.searchResult.invoiceEntries.map((data, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{data.invoiceNo}</td>
                                        <td>
                                            <Moment local format="YYYY-MM-DD">
                                                {data.invoiceDate}
                                            </Moment>
                                        </td>
                                        <td style={{textAlign: "right"}}>
                                            {data.totalInvoiceAmount.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </td>
                                        <td>{data.status}</td>
                                        <td>{data.isApproved ? "Yes" : "No"}</td>
                                        <td>{data.isPaid ? "Yes" : "No"}</td>
                                        <td>
                                            {(data.isApproved && !data.isPaid) ? (
                                                <button className={"btn btn-sm btn-danger"}
                                                        onClick={() => this.handleShowReverseInvoiceModal(data.id)}>Reverse
                                                    Invoice</button>) : (null)}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    );
                }

                responseContents = (
                    <div>
                        <div className="ktm-funding-name">
                            {this.state.searchResult.policyName}
                        </div>
                        <div className="ktm-funding-balance-container">
                            <div className="ktm-funding-balance-label">
                                Current Balance:{" "}
                                <div className="ktm-funding-balance">
                                    $
                                    {this.state.searchResult.currentBalance.toLocaleString(
                                        "en-US",
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </div>
                            </div>
                            <div className="ktm-funding-balance-label ktm-funding-balance-label-right">
                                <div style={{paddingRight: 15}}>Submit Funding</div>
                                <div>
                                    <select
                                        className="form-control"
                                        onChange={(e) => this.setNote(e)}
                                    >
                                        <option>Select Type</option>
                                        <optgroup label="Payment Method">
                                            <option value="Cheque">Cheque</option>
                                            <option value="Direct Deposit">Direct Deposit</option>
                                            <option value="e-Tranfer">e-Transfer</option>
                                            <option value="EA">EA</option>
                                            <option value="Telpay">Telpay</option>
                                            <option value="Wire">Wire</option>
                                            <option value="EFT">EFT</option>
                                        </optgroup>
                                        <option disabled>----------</option>
                                        <optgroup label="Bank">
                                            <option value="ATB">ATB Financial</option>
                                            <option value="BMO">BMO Bank of Montreal</option>
                                            <option value="BNC">BNC</option>
                                            <option value="BoA">BoA</option>
                                            <option value="Central 1">Central 1</option>
                                            <option value="CIBC">CIBC</option>
                                            <option value="HSBC">HSBC</option>
                                            <option value="MLB">MLB</option>
                                            <option value="RBC">RBC</option>
                                            <option value="Scotiabank">Scotiabank</option>
                                            <option value="TD">TD Canada Trust</option>
                                            <option value="Credit Union / Other">
                                                Credit Union / Other
                                            </option>
                                            <option value="Other">Other</option>
                                        </optgroup>
                                        <option disabled>----------</option>
                                        <option value="Reversal">Funding Reversal</option>
                                    </select>
                                    <div>
                                        <div>
                                            {this.state.note != null &&
                                            this.state.note.toLowerCase().includes("cheque") ? (
                                                <div>
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) => this.setChqNo(e)}
                                                        placeholder="Cheque No."
                                                        value={this.state.chqNo}
                                                        onKeyPress={(e) => {
                                                            const keyCode = e.keyCode || e.which;
                                                            if (keyCode < 48 || keyCode > 57) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                            {this.state.note != null &&
                                            this.state.note.toLowerCase().includes("reversal") ? (
                                                <div>
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.setState({reversalNote: e.target.value})}
                                                        placeholder="Note"
                                                        value={this.state.reversalNote}
                                                    />
                                                </div>
                                            ) : null}
                                            <div>
                                                <input
                                                    className="form-control"
                                                    onChange={(e) => this.setAmount(e)}
                                                    value={this.state.amount}
                                                    placeholder="Amount"
                                                    onKeyPress={(e) => {
                                                        // Allow only digits and decimal point
                                                        const keyCode = e.keyCode || e.which;
                                                        const keyValue = String.fromCharCode(keyCode);
                                                        const validCharRegex = /[0-9.]/;
                                                        if (!validCharRegex.test(keyValue)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    if (this.state.note != null && this.state.reversalNote != null && this.state.note.toLowerCase().includes("reversal")) {
                                                        this.handleShowReverseFundingModal(this.state.amount, this.state.reversalNote);
                                                    } else {
                                                        this.verifyFunding();
                                                    }
                                                }}
                                                className="btn btn-primary"
                                                type="button"
                                                style={{width: "100%", marginTop: 15}}
                                                disabled={
                                                    this.state.note == null || this.state.amount <= 0
                                                }
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>Most Recent Funding (Last 5)</div>
                        <div>{ledgerTable}</div>
                        <div>Most Recent Invoice (Last 5)</div>
                        <div>{invoiceTable}</div>
                    </div>
                );
            }

            let paymentFiles = <div>No paid invoice available</div>
            if (this.state.paidInvoice.length > 0) {
                paymentFiles = <Table className={"mt-2"} striped bordered hover>
                    <thead>
                    <tr>
                        <td>File No.</td>
                        <td>Date</td>
                        <td>Count</td>
                        <td className={"text-right"}>Total Amount</td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.paidInvoice.map((data, key) => {
                        return (
                            <tr key={key}>
                                <td>{data.fileNum}</td>
                                <td>
                                    <Moment local format="YYYY-MM-DD">
                                        {data.createdAt}
                                    </Moment>
                                </td>
                                <td>{data.count}</td>
                                <td style={{textAlign: "right"}}>
                                    {data.totalAmount.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </td>
                                <td>
                                    <button className={"btn btn-sm btn-success"}
                                            onClick={() => this.downloadPayment(data.id, data.fileNum)}>Download EFT
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            }

            let padInvoiceTable = null;
            if (this.state.padInvoices.length > 0) {
                let padInvoiceRows = this.state.padInvoices.map((data, i) => {
                    const paymentFileEnabled = this.isPaymentFileEnabled(data.date);
                    return (
                        <tr key={i}>
                            <td>{data.date}</td>
                            <td>{data.policyCount}</td>
                            <td>${(data.policyTotal).toFixed(2)}</td>
                            <td style={{textAlign: 'center'}}>
                                <button className="btn btn-sm btn-outline-primary d-inline-flex align-items-center me-2"
                                        onClick={() => this.downloadPadFile(data.date)}>
                                    <MdOutlineSimCardDownload style={{marginRight: '5px'}}/> PAD File
                                </button>
                                <button
                                    className={`btn btn-sm d-inline-flex align-items-center me-2 ${
                                        paymentFileEnabled ? 'btn-outline-primary' : 'btn-outline-danger'
                                    }`}
                                    onClick={() => this.downloadPaymentFile(data.date)}
                                    disabled={!paymentFileEnabled}
                                >
                                    <MdOutlineSimCardDownload style={{marginRight: '5px'}}/> Payment File
                                </button>

                                {/*<button*/}
                                {/*    className={`btn btn-sm d-inline-flex align-items-center me-2 ${*/}
                                {/*        paymentFileEnabled ? 'btn-outline-primary' : 'btn-outline-danger'*/}
                                {/*    }`}*/}
                                {/*    onClick={() => this.downloadCorehealthSummary(data.date)}*/}
                                {/*    disabled={!paymentFileEnabled}*/}
                                {/*>*/}
                                {/*    <MdOutlineSimCardDownload style={{marginRight: '5px'}}/> Corehealth Report*/}
                                {/*</button>*/}
                            </td>
                        </tr>
                    );
                });

                padInvoiceTable = (
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Invoice Date</th>
                            <th>Policy Count</th>
                            <th>Total Amount</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {padInvoiceRows}
                        </tbody>
                    </table>
                );
            }

            return (
                <div>
                    {fundingModal}
                    {reverseFundingModal}
                    {reverseInvoiceModal}

                    <div className="ktm-financials-content">
                        <>
                            <div className="ktm-financials-label" style={{minWidth: 1200}}>Corehealth Dashboard</div>
                            <div className="container mt-2">
                                <div className="mt-4">
                                    <div className="d-flex align-items-start">
                                        <Accordion defaultActiveKey="0" style={{width: '100%'}}>
                                            {/*<Accordion.Item eventKey="0">*/}
                                            {/*    <Accordion.Header>*/}
                                            {/*        Policy Funding and Reversal*/}
                                            {/*    </Accordion.Header>*/}
                                            {/*    <Accordion.Body style={{minHeight: 250}}>*/}
                                            {/*        <div style={{width: '100%'}}>*/}
                                            {/*            <div>*/}
                                            {/*                <div className="ktm-financials-content">*/}
                                            {/*                    <div className="input-group mb-3">*/}
                                            {/*                        <input*/}
                                            {/*                            onKeyDown={this.handleKeyDown}*/}
                                            {/*                            type="text"*/}
                                            {/*                            className="form-control"*/}
                                            {/*                            name="searchInput"*/}
                                            {/*                            onChange={(e) => this.setInput(e)}*/}
                                            {/*                            value={this.state.searchInput}*/}
                                            {/*                            placeholder={*/}
                                            {/*                                "Enter policy name or policy number"*/}
                                            {/*                            }*/}
                                            {/*                        />*/}
                                            {/*                        <button*/}
                                            {/*                            onClick={() => this.submitSearch()}*/}
                                            {/*                            className="btn btn-outline-primary"*/}
                                            {/*                            type="button"*/}
                                            {/*                        >*/}
                                            {/*                            Search*/}
                                            {/*                        </button>*/}
                                            {/*                    </div>*/}
                                            {/*                </div>*/}
                                            {/*                {responseContents}*/}
                                            {/*                {fundingModal}*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </Accordion.Body>*/}
                                            {/*</Accordion.Item>*/}
                                            

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    Corehealth PAD (Debit) and Commissions/Premiums (Credit)
                                                </Accordion.Header>
                                                <Accordion.Body style={{minHeight: 250}}>
                                                    {padInvoiceTable}
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            {/*<Accordion.Item eventKey="4">*/}
                                            {/*    <Accordion.Header>*/}
                                            {/*        Payment Files (Generate Commission Payments)*/}
                                            {/*    </Accordion.Header>*/}
                                            {/*    <Accordion.Body style={{minHeight: 250}}>*/}
                                            {/*        <div>*/}

                                            {/*            <button className={"btn btn-success"}*/}
                                            {/*                    onClick={() => this.runPaymentProcess()}*/}
                                            {/*                    disabled={this.state.loading}>*/}
                                            {/*                Run Payment Process*/}
                                            {/*                {this.state.loading &&*/}
                                            {/*                    <Spinner style={{marginLeft: 10}} animation={"border"}*/}
                                            {/*                             variant={"hint"}*/}
                                            {/*                             size={"sm"}/>}*/}
                                            {/*            </button>*/}
                                            {/*        </div>*/}
                                            {/*        <div>*/}
                                            {/*            {paymentFiles}*/}
                                            {/*        </div>*/}
                                            {/*    </Accordion.Body>*/}
                                            {/*</Accordion.Item>*/}
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            );
        }
    }
);
