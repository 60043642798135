import CustomPagination from "components/CustomPagination";
import {Button, Spinner} from "react-bootstrap";
import React from "react";
import {CSVLink} from "react-csv";
import {CustomDateRangePicker} from "components/CustomDateRangePicker";

export default class OpsTaxReport extends React.Component<any, any> {
    state = {
        loading: true,
        success: false,
        opsTaxes: [],
        ontarioTaxes: [],
        total: 0,
        currentPage: 1,
        itemsPerPage: 10,
        fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        toDate: new Date(),
        filterType: "month",
        filterYear: new Date().getFullYear(),
        filterMonth: new Date().getMonth() + 1,
    };

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.fromDate !== this.state.fromDate ||
            prevState.toDate !== this.state.toDate
        ) {
            this.setState({loading: true});
            this.initialize();
        }
    }

    setCurrentPage = (page: number) => {
        this.setState({currentPage: page});
    };

    initialize = () => {
        this.setState({loading:true});
        let url = "/api/opstransfer/getopstaxreport?filterType=" + this.state.filterType;

        if (this.state.filterType === "month") {
            url += "&month=" + this.state.filterMonth;
            url += "&year=" + this.state.filterYear;
        } else {
            url += "&dateFrom=" + this.state.fromDate.toISOString();
            url += "&dateTo=" + this.state.toDate.toISOString();
        }

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    this.setState({
                        loading: false,
                        opsTaxes: data.data || [],
                        total: data.total || 0,
                        ontarioTaxes: data.claimsSums ? [data.claimsSums] : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        opsTaxes: [],
                        ontarioTaxes: [],
                        total: 0,
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
            });
    };

    setFromDate = (date) => {
        this.setState({
            fromDate: date,
        });
    };

    setToDate = (date) => {
        this.setState({
            toDate: date,
        });
    };

    render() {
        const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
        const currentRecords = this.state.opsTaxes.slice(
            indexOfFirstRecord,
            indexOfLastRecord
        );
        const nPages = Math.ceil(
            this.state.opsTaxes.length / this.state.itemsPerPage
        );

        let opsTaxesTable = null;
        if (this.state.opsTaxes.length > 0) {
            opsTaxesTable = (
                <>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Trans Type</th>
                            <th className="text-end">Total Trans Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentRecords.map((item, i) => (
                            <tr key={i}>
                                <td>{item.transType}</td>
                                <td className="text-end">
                                    {item.totalTransAmount.toLocaleString(undefined, {
                                        style: "currency",
                                        currency: "USD",
                                    })}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div
                        className="pagination-container"
                        style={{
                            position: "relative",
                            right: "10px",
                            margin: "0px",
                            justifyContent: "end",
                        }}
                    >
                        <div className="pagination-info">
              <span>
                Showing {indexOfFirstRecord + 1} -{" "}
                  {indexOfFirstRecord + currentRecords.length} entries out of{" "}
                  {this.state.opsTaxes.length} entries
              </span>
                        </div>
                        <CustomPagination
                            nPages={nPages}
                            currentPage={this.state.currentPage}
                            setCurrentPage={this.setCurrentPage}
                        />
                    </div>
                </>
            );
        }

        let ontarioTaxesTable = null;
        if (this.state.ontarioTaxes && this.state.ontarioTaxes.length > 0) {
            const row = this.state.ontarioTaxes[0];
            ontarioTaxesTable = (
                <table className="table mt-4">
                    <thead>
                    <tr>
                        <th className="text-end">Total ON Claims</th>
                        <th className="text-end">Total ON Admin Fee</th>
                        <th className="text-end">Total ON RST Amount</th>
                        <th className="text-end">Total ON Premium Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="text-end">
                            {row.totalEmployeePayment.toLocaleString(undefined, {
                                style: "currency",
                                currency: "USD",
                            })}
                        </td>
                        <td className="text-end">
                            {row.totalAdminFee.toLocaleString(undefined, {
                                style: "currency",
                                currency: "USD",
                            })}
                        </td>
                        <td className="text-end">
                            {row.totalRstAmount.toLocaleString(undefined, {
                                style: "currency",
                                currency: "USD",
                            })}
                        </td>
                        <td className="text-end">
                            {row.totalPremiumAmount.toLocaleString(undefined, {
                                style: "currency",
                                currency: "USD",
                            })}
                        </td>
                    </tr>
                    </tbody>
                </table>
            );
        }

        const today = new Date().toISOString().slice(0, 10);
        const fileName = `opstaxes_${today}.csv`;

        return (
            <>
                {this.state.loading ? (
                    <div className="financial-spinner">
                        <Spinner animation="border"/>
                    </div>
                ) : (
                    <div>
                        {/*<div className="mb-2 d-flex justify-content-between">*/}
                        {/*    <CustomDateRangePicker*/}
                        {/*        fromDate={this.state.fromDate}*/}
                        {/*        toDate={this.state.toDate}*/}
                        {/*        setFromDate={this.setFromDate}*/}
                        {/*        setToDate={this.setToDate}*/}
                        {/*    />*/}
                        {/*    {this.state.opsTaxes.length > 0 ? (*/}
                        {/*        <div>*/}
                        {/*            /!* Export to CSV *!/*/}
                        {/*            <CSVLink data={this.state.opsTaxes} filename={fileName}>*/}
                        {/*                <Button variant="primary" className="csv-button export-to-csv">*/}
                        {/*                    <span className="csv-text">Export to CSV</span>*/}
                        {/*                </Button>*/}
                        {/*            </CSVLink>*/}
                        {/*        </div>*/}
                        {/*    ) : null}*/}
                        {/*</div>*/}

                        <div className="mb-2 d-flex align-items-end flex-wrap gap-2">
                            {/* Filter Type Selector */}
                            <div>
                                <select
                                    value={this.state.filterType}
                                    onChange={(e) => this.setState({filterType: e.target.value})}
                                    className="form-select form-select-sm w-auto"
                                >
                                    <option value="month">Select by Month</option>
                                    <option value="date">Select by Date Range</option>
                                </select>
                            </div>

                            {this.state.filterType === "month" && (
                                <>
                                    {/* Month Dropdown */}
                                    <div style={{marginTop:10}}>
                                        <select
                                            value={this.state.filterMonth}
                                            onChange={(e) => this.setState({filterMonth: parseInt(e.target.value)})}
                                            className="form-select form-select-sm w-auto"
                                        >
                                            {Array.from({length: 12}, (_, i) => (
                                                <option key={i + 1} value={i + 1}>
                                                    {new Date(0, i).toLocaleString("default", {month: "long"})}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* Year Dropdown */}
                                    <div style={{marginLeft:15}}>
                                        <select
                                            value={this.state.filterYear}
                                            onChange={(e) => this.setState({filterYear: parseInt(e.target.value)})}
                                            className="form-select form-select-sm w-auto"
                                        >
                                            {Array.from({length: new Date().getFullYear() - 2020 + 1}, (_, i) => {
                                                const y = new Date().getFullYear() - i;
                                                return (
                                                    <option key={y} value={y}>
                                                        {y}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </>
                            )}

                            {this.state.filterType === "date" && (
                                <div>
                                    <CustomDateRangePicker
                                        fromDate={this.state.fromDate}
                                        toDate={this.state.toDate}
                                        setFromDate={this.setFromDate}
                                        setToDate={this.setToDate}
                                    />
                                </div>
                            )}

                            <div>
                                <button
                                    onClick={this.initialize}
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        
                        {/* Table */}
                        <h2 className={"my-3"} style={{'fontSize': 16, 'fontWeight': 'bold'}}>Taxes (All)</h2>
                        {opsTaxesTable}
                        <h2 style={{'fontSize': 16, 'fontWeight': 'bold'}}>Taxes (Ontario)</h2>
                        {ontarioTaxesTable}
                    </div>
                )}
            </>
        );
    }
}
