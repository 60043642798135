import React from 'react';
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from 'ktm-ui';
import { KTMApi } from 'api/KTMApi';
import { Layout, Dialog, Button, Display, Line, Card, Column, Panel } from 'ktm-ui';
import toastr from "toastr";
import CustomIntegerInput from 'components/CustomIntegerInput';

interface IdsBenCat {
    Id: string;
    CreditCalcMethod: string;
    FlexSingleLimit: number;
    FlexCoupleLimit: number;
    FlexFamilyLimit: number;
    FlexSinglePercent: number;
    FlexCouplePercent: number;
    FlexFamilyPercent: number;
    AllowRSPOption: boolean;
    AllowStockOption: boolean;
    HasHSA: boolean;
    HasLSA: boolean;
    HSALabel: string;
    LSALabel: string;
    RSPLabel: string;
    Stocklabel: string;
    DefaultRspAllocation: number;
    DefaultStockAllocation: number;
    DefaultHSAAllocation: number;
    DefaultLSAAllocation: number;
    AllowLSADependentClaims: boolean;
    HSACap: number;
    LSACap: number;
    RSPCap: number;
    StockCap: number;
    AllowTFSAOption: boolean;
    TFSALabel: string;
    DefaultTFSAAllocation: number;
    TFSACap: number;
}

type State = {
    hsaCap: number;
    lsaCap: number;
    rspCap: number;
    inputPrefix: string;
    tfsaCap: number;
};

export default KTMScreen({ name: "planadmin.ben-limit.add-flex" }, class extends React.Component<{ limit_id: string }, State> {

    data: {
        dsBenCat: IdsBenCat;
    };

    constructor(props: { limit_id: string }) {
        super(props);
        this.state = {
            hsaCap: 0,
            lsaCap: 0,
            rspCap: 0,
            tfsaCap: 0,
            inputPrefix: "$"
        };
    }

    render() {
        return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen, {
            "@limit_id": this.props.limit_id,
        });
        screen.title = "Add Flex Benefit";
        this.updateInputPrefix(screen.getValue("@dsBenCat.CreditCalcMethod"))
        await screen.triggerAction(this.set_limit_visibility);
    }

    updateInputPrefix(creditCalcMethod: string) {
        this.setState({inputPrefix : creditCalcMethod === "SALARY" ? "%" : "$"})
    }

    handleHsaCapInputChange = (value: number) => {
        this.setState({ hsaCap: value });
        this.data.dsBenCat.HSACap = value;
    };

    handleLsaCapInputChange = (value: number) => {
        this.setState({ lsaCap: value });
        this.data.dsBenCat.LSACap = value;
    };

    handleRspCapInputChange = (value: number) => {
        this.setState({ rspCap: value });
        this.data.dsBenCat.RSPCap = value;
    };

    handleTfsaCapInputChange = (value: number) => {
        this.setState({ tfsaCap: value });
        this.data.dsBenCat.TFSACap = value;
    };

    main({ field, draw, action }: RenderEvent) {
        return (
            <Layout dialogSize="large">
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({ field, draw, action }: RenderEvent) {
        return (
            <Dialog.Header label="Add Flex Benefit" />
        );
    }

    footer({ field, draw, action }: RenderEvent) {
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary" />
            </Dialog.Footer>
        );
    }

    limits({field,draw,action}:RenderEvent){
        return (
            <Line>
                <Display required field={field("FlexSingleLimit")} />
                <Display required field={field("FlexCoupleLimit")} />
                <Display required field={field("FlexFamilyLimit")} />
            </Line>
        );
    }

    percentages({ field, draw, action }: RenderEvent) {
        return (
            <Line>
                <Display required field={field("FlexSinglePercent")} />
                <Display required field={field("FlexCouplePercent")} />
                <Display required field={field("FlexFamilyPercent")} />
            </Line>
        );
    }

    amounts({ field, draw, action }: RenderEvent) {
        return (
            <Card>
                {draw(this.limits)}
                {draw(this.percentages)}
            </Card>
        );
    }

    hsa({ field, draw, action }: RenderEvent) {
        if (!this.data.dsBenCat.HasHSA) {
            this.data.dsBenCat.HSALabel = "";
            this.data.dsBenCat.DefaultHSAAllocation = 0;
        }
        return (
            <Card label="HSA">
                <Display required field={field("HasHSA")} />
                <Line>
                    <Display required field={field("DefaultHSAAllocation")} disabled={!this.data.dsBenCat.HasHSA} />
                    <Display field={field("HSALabel")} disabled={!this.data.dsBenCat.HasHSA} />
                </Line>
                {(this.data.dsBenCat.DefaultHSAAllocation === 0 || this.data.dsBenCat.CreditCalcMethod !== "SALARY") && (
                <Line>
                    <CustomIntegerInput
                        label="HSA Cap"
                        value={this.data.dsBenCat.HSACap}
                        onChange={this.handleHsaCapInputChange}
                        icon={this.state.inputPrefix}
                    />
                </Line>
                )}
            </Card>
        );
    }

    lsa({ field, draw, action }: RenderEvent) {
        if (!this.data.dsBenCat.HasLSA) {
            this.data.dsBenCat.AllowLSADependentClaims = false;
            this.data.dsBenCat.LSALabel = "";
            this.data.dsBenCat.DefaultLSAAllocation = 0;
        }
        return (
            <Card label="LSA">
                <Line>
                    <Display required field={field("HasLSA")} />
                    <Display required field={field("AllowLSADependentClaims")} disabled={!this.data.dsBenCat.HasLSA} />
                </Line>
                <Line>
                    <Display required field={field("DefaultLSAAllocation")} disabled={!this.data.dsBenCat.HasLSA} />
                    <Display field={field("LSALabel")} disabled={!this.data.dsBenCat.HasLSA} />
                </Line>
                {(this.data.dsBenCat.DefaultLSAAllocation === 0 || this.data.dsBenCat.CreditCalcMethod !== "SALARY") && (
                <Line>
                    <CustomIntegerInput
                        label="LSA Cap"
                        value={this.data.dsBenCat.LSACap}
                        onChange={this.handleLsaCapInputChange}
                        icon={this.state.inputPrefix}
                    />
                </Line>
                )}
            </Card>
        );
    }

    rsp({ field, draw, action }: RenderEvent) {
        if (!this.data.dsBenCat.AllowRSPOption) {
            this.data.dsBenCat.RSPLabel = "";
            this.data.dsBenCat.DefaultRspAllocation = 0;
        }
        return (
            <Card label="RSP">
                <Display field={field("AllowRSPOption")} />
                <Line>
                    <Display field={field("DefaultRspAllocation")} disabled={!this.data.dsBenCat.AllowRSPOption} />
                    <Display field={field("RSPLabel")} disabled={!this.data.dsBenCat.AllowRSPOption} />
                </Line>
                {(this.data.dsBenCat.DefaultRspAllocation === 0 || this.data.dsBenCat.CreditCalcMethod !== "SALARY") && (
                <Line>
                    <CustomIntegerInput
                        label="RSP Cap"
                        value={this.data.dsBenCat.RSPCap}
                        onChange={this.handleRspCapInputChange}
                        icon={this.state.inputPrefix}
                    />
                </Line>
                )}
            </Card>
        );
    }

    tfsa({ field, draw, action }: RenderEvent) {
        if (!this.data.dsBenCat.AllowTFSAOption) {
            this.data.dsBenCat.TFSALabel = "";
            this.data.dsBenCat.DefaultTFSAAllocation = 0;
        }
        return (
            <Card label="TFSA">
                <Display field={field("AllowTFSAOption")} />
                <Line>
                    <Display field={field("DefaultTFSAAllocation")} disabled={!this.data.dsBenCat.AllowTFSAOption} />
                    <Display field={field("TFSALabel")} disabled={!this.data.dsBenCat.AllowTFSAOption} />
                </Line>
                {(this.data.dsBenCat.DefaultTFSAAllocation === 0 || this.data.dsBenCat.CreditCalcMethod !== "SALARY") && (
                <Line>
                    <CustomIntegerInput
                        label="TFSA Cap"
                        value={this.data.dsBenCat.TFSACap}
                        onChange={this.handleTfsaCapInputChange}
                        icon={this.state.inputPrefix}
                    />
                </Line>
                )}
            </Card>
        );
    }

    options_panel({ field, draw, action }: RenderEvent) {
        return (
            <Panel>
                <Column>
                    {draw(this.hsa)}
                    {draw(this.lsa)}
                </Column>
                <Column>
                    {draw(this.rsp)}
                    {draw(this.tfsa)}
                </Column>
            </Panel>
        );
    }

    amounts_panel({ field, draw, action }: RenderEvent) {
        return (
            <Panel>
                <Column>
                    {draw(this.amounts)}
                </Column>
            </Panel>
        );
    }

    main_tab({ field, draw, action }: RenderEvent) {
        return (
            <Panel>
                <Column>
                    {draw(this.amounts_panel)}
                    {draw(this.options_panel)}
                </Column>
            </Panel>
        );
    }

    async submit({ screen }: ActionEvent) {
        this.data.dsBenCat.DefaultHSAAllocation = Math.round(this.data.dsBenCat.DefaultHSAAllocation);
        this.data.dsBenCat.DefaultLSAAllocation = Math.round(this.data.dsBenCat.DefaultLSAAllocation);
        this.data.dsBenCat.DefaultRspAllocation = Math.round(this.data.dsBenCat.DefaultRspAllocation);
        this.data.dsBenCat.DefaultTFSAAllocation = Math.round(this.data.dsBenCat.DefaultTFSAAllocation);

        let totalAllocation = this.data.dsBenCat.DefaultHSAAllocation +
                              this.data.dsBenCat.DefaultLSAAllocation +
                              this.data.dsBenCat.DefaultRspAllocation +
                              this.data.dsBenCat.DefaultTFSAAllocation;

        if (totalAllocation > 100) {
            toastr.error("Total allocation cannot exceed 100%.");
            return;
        } else if (totalAllocation < 100) {
            toastr.error("Total allocation must equal 100%.");
            return;
        }

        if (this.data.dsBenCat.FlexSingleLimit == null)
            this.data.dsBenCat.FlexSingleLimit = 0;
        if (this.data.dsBenCat.FlexCoupleLimit == null)
            this.data.dsBenCat.FlexCoupleLimit = 0;
        if (this.data.dsBenCat.FlexFamilyLimit == null)
            this.data.dsBenCat.FlexFamilyLimit = 0;
        if (this.data.dsBenCat.FlexSinglePercent == null)
            this.data.dsBenCat.FlexSinglePercent = 0;
        if (this.data.dsBenCat.FlexCouplePercent == null)
            this.data.dsBenCat.FlexCouplePercent = 0;
        if (this.data.dsBenCat.FlexFamilyPercent == null)
            this.data.dsBenCat.FlexFamilyPercent = 0;
        
        if(this.data.dsBenCat.CreditCalcMethod === "SALARY")
        {
            if (this.data.dsBenCat.DefaultHSAAllocation > 0)
                this.data.dsBenCat.HSACap = 0;
            if (this.data.dsBenCat.DefaultLSAAllocation > 0)
                this.data.dsBenCat.LSACap = 0;
            if (this.data.dsBenCat.DefaultRspAllocation > 0)
                this.data.dsBenCat.RSPCap = 0;
            if (this.data.dsBenCat.DefaultTFSAAllocation > 0)
                this.data.dsBenCat.TFSACap = 0;
        }   

        await KTMApi.action(screen, "submit", this.data.dsBenCat);
        screen.recordChanged("updated", this.props.limit_id);
        screen.close({ "continue": true });
    }

    async set_limit_visibility({ screen }: ActionEvent) {
        screen.hide(this.limits);
        screen.hide(this.percentages);
        if (screen.getValue("@dsBenCat.CreditCalcMethod") === "SALARY") {
            screen.unhide(this.percentages);
        } else {
            screen.unhide(this.limits);
        }
    }
});
