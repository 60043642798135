import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Column,
  Panel,
  Display,
  Line,
  Card,
} from "ktm-ui";
import moment from "moment";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  Employee_Id: string;
  BenefitCategory_Id: string;
}

interface IdsEmployee {
  Id: string;
  Name: string;
  HireDate: string;
  WaitingPeriodMonths: number;
  BenefitStartDate: string;
  IsStartDateAdjusted: boolean;
}

interface IdsBenefitYear {
  Id: string;
  StartDate: string;
}

export default KTMScreen(
  { name: "planadmin.employee-benefits.edit-employee-start-date" },
  class extends React.Component<{
    employeeBenefit_id: string;
  }> {
    data: {
      dsBenefit: IdsBenefit;
      dsEmployee: IdsEmployee;
      dsBenefitYear: IdsBenefitYear;
    };

    state = {
      resetPAapprovedDate: false,
      showStartDateInput: false,
      showStartDateModal: false,
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@employeeBenefit_id": this.props.employeeBenefit_id,
      });
      screen.title = "Edit employee benefit limits";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label={"Edit employee start date"} />;
    }

    footer({ field, draw, action }: RenderEvent) {
        if (this.data.dsEmployee.BenefitStartDate != this.systemGeneratedBenefitStartDate())
        {
            this.data.dsEmployee.IsStartDateAdjusted = true;
        }
        else 
        {
            this.data.dsEmployee.IsStartDateAdjusted = false;
        }

      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
          />
        </Dialog.Footer>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.start_date)}
          </Column>
        </Panel>
      );
    }

    start_date({ field, draw, action }: RenderEvent) {
      var systemBenefitStartDate = this.systemGeneratedBenefitStartDate();
      return (
        <div>
          <Card>
            <Line>
              <div className="RT-TextBox">
                <label>System Calculated Benefit Start Date</label>
                <div className="RT-TextBox__container">
                  {systemBenefitStartDate}
                </div>
              </div>
              <Display
                label="PA Approved Benefit Start Date"
                field={field("@dsEmployee.BenefitStartDate")}
              />
            </Line>
          </Card>
          <a
            href="#"
            className="RT-Link"
            onClick={() => this.reset_PAapprovedDate(systemBenefitStartDate)}
            style={{ paddingTop: "10px" }}
          >
            Click here to clear PA Approved Benefit Start Date Override
          </a>
        </div>
      );
    }

    systemGeneratedBenefitStartDate() {
      var systemStartDate;
      if (this.data.dsEmployee.HireDate) {
        systemStartDate = this.data.dsEmployee.HireDate;
        if (this.data.dsEmployee.WaitingPeriodMonths) {
          systemStartDate = moment(this.data.dsEmployee.HireDate)
            .add(this.data.dsEmployee.WaitingPeriodMonths, "months")
            .format("YYYY-MM-DD");
        }
      }
      return systemStartDate;
    }

    reset_PAapprovedDate = (systemBenefitStartDate: any) => {
      this.data.dsEmployee.BenefitStartDate = systemBenefitStartDate;
      this.data.dsEmployee.IsStartDateAdjusted = false;
      this.setState({
        resetPAapprovedDate: true,
        showStartDateInput: false,
      });
    };

    async submit({ screen }: ActionEvent) {
      const employeeLogs = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          EmployeeId:this.data.dsEmployee.Id,
          EmployeeBenefit_Id: this.props.employeeBenefit_id,
          BenefitStartDate: this.data.dsEmployee.BenefitStartDate,
          IsStartDateAdjusted:
            this.data.dsEmployee.IsStartDateAdjusted,
        }),
      };

      await KTMApi.action(screen, "submit", {
        "@employeeBenefit_id": this.props.employeeBenefit_id,
        "@dsEmployee.BenefitStartDate": this.data.dsEmployee.BenefitStartDate,
        "@dsEmployee.IsStartDateAdjusted":
          this.data.dsEmployee.IsStartDateAdjusted,
      }).then((data) => {
        fetch("/api/PlanAdmin/updatestartdatelogs", employeeLogs);
      });
        screen.close({ continue: true });
    }
  }
);
