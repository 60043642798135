import axios from "axios";
import { CustomLoading } from "components/CustomLoading";
import CustomToolTip from "components/CustomToolTip";
import { Card, Line } from "ktm-ui";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import toastr from 'toastr';
class CustomCategoriesScreen extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            selectedAccountType: "HSA",
            showEditCustomCategoryModal: false,
            selectedCustomCategory: {},
            showRemoveCustomCategoryModal: false,
            showRemoveBtn: false,
            error: null
        }
    }

    componentDidMount() {
        this.getNumberofEmployees();
        this.getCustomCategories();
    }

    getNumberofEmployees = () => {
        try {
            const { category_id } = this.props;
            axios.get("/api/BenefitCategories/getvalicdationofremovingcustomcat", {
                params: { 
                    benefitCategoryId: category_id 
                }
            }).then((response) => {
                if (response.data.success) {
                    this.setState({
                        showRemoveBtn: response.data.showRemoveBtn
                    });
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    getCustomCategories = () => {
        try {
            const { policy_id, limit_id, category_id } = this.props;
            axios.get("/api/BenefitCategories/getcustomcategories", {
                params: { 
                    policyId: policy_id, 
                    bencatLimitId: limit_id, 
                    benefitCategoryId: category_id 
                }
            }).then((response) => {
                if (response.data.success) {
                    this.setState({
                        data: response.data.customCategories,
                        showAddHSABtn: response.data.showAddHSACustomCategory,
                        showAddLSABtn: response.data.showAddLSACustomCategory,
                        loading: false
                    });
                } else {
                    this.setState({
                        data: [],
                        loading: false,
                        showAddHSABtn: true,
                        showAddLSABtn: true,
                    });
                }
            });
        } catch (error) {
            this.setState({loading: false });
        }
    }

    updateCustomCategory = (
        selectedCustomCategory: any, 
        newName: string, 
        newDescription: string, 
        newLimit: number, 
        allowDependentClaims: boolean, 
        allowFlowThrough: boolean,
        nonTaxable: boolean,
    ) => {
        try {
            const { policy_id, limit_id, category_id } = this.props;
            
            const { 
                customCategory_Id,
                customCategoryLimit_Id
            } = selectedCustomCategory;

            axios.post("/api/BenefitCategories/updatecustomcategory", {
                policyId: policy_id,
                bencatLimitId: limit_id,
                benefitCategoryId: category_id,
                customCategoryId: customCategory_Id,
                customCategoryLimitId : customCategoryLimit_Id,
                name: newName,
                description: newDescription,
                limit: newLimit,
                allowDependentClaims: allowDependentClaims,
                allowFlowThrough: allowFlowThrough,
                nonTaxable: nonTaxable,
            }).then((response) => {
                if (response.data.success) {
                    this.setState({
                        loading: true,
                        showEditCustomCategoryModal: false
                    });

                    this.getCustomCategories();
                } else {
                    toastr.error(response.data.message, "Error");
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    removeCustomCategory = (selectedCustomCategory: any) => {
        try {
            const { 
                customCategory_Id,
                customCategoryLimit_Id
            } = selectedCustomCategory;

            axios.delete("/api/BenefitCategories/removecustomcategory", {
                params: {
                    customCategoryId: customCategory_Id,
                    customCategoryLimitId : customCategoryLimit_Id,
                }
            }).then((response) => {
                if (response.data.success) {
                    this.setState({
                        loading: true,
                    });

                    this.getCustomCategories();
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    handleRemoveClick = (customCategory) => {
        this.setState({
            showRemoveCustomCategoryModal: true,
            selectedCustomCategory: customCategory
        });
    }

    handleEditClick = (customCategory) => {
        this.setState({
            showEditCustomCategoryModal: true,
            selectedCustomCategory: customCategory
        });
    }

    renderCustomCategories = () => {
        const { data } = this.state;

        if(data.length === 0) {
            return (<></>);
        }

        const categories = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].accountType === this.props.accountType) {
                categories.push(
                    <div key={i} className="RT-Section">
                        <div className="RT-Section__header">
                            <div className="RT-Section__title">{data[i].accountType} Enhanced Benefit</div>
                            <div className="RT-Section__actions">
                                <a href="#" className="RT-Link" onClick={() => this.handleEditClick(data[i])}>
                                    Edit
                                </a>
                                {this.state.showRemoveBtn && (
                                    <a href="#" className="RT-Link" onClick={() => this.handleRemoveClick(data[i])}>
                                        Remove
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="RT-Line">
                            <div className="RT-Line__content RT-Line__content--space-evenly">
                                <div className="RT-Line__item">
                                    <div className="RT-Static-Field">
                                        <label className="RT-Static-Field__label">Name</label>
                                        <div className="RT-Static-Field__content">{data[i].name}</div>
                                    </div>
                                </div>
                                <div className="RT-Line__item">
                                    <div className="RT-Static-Field">
                                        <label className="RT-Static-Field__label">Description</label>
                                        <div className="RT-Static-Field__content">{data[i].description}</div>
                                    </div>
                                </div>
                                <div className="RT-Line__item">
                                    <div className="RT-Static-Field">
                                        <label className="RT-Static-Field__label">Limit</label>
                                        <div className="RT-Static-Field__content"
                                             style={{fontWeight: 500}}>${data[i].limit}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="RT-Line">
                            <div className="RT-Line__content RT-Line__content--space-evenly">
                                <div className="RT-Line__item">
                                        <div className="RT-Static-Field">
                                            <label className="RT-Static-Field__label">Allow Dependent Claims</label>
                                            <div
                                                className="RT-Static-Field__content">{data[i].allowDependentClaims ? "Yes" : "No"}</div>
                                        </div>
                                </div>
                                {data[i].allowFlowThrough ? <></> : 
                                <div className="RT-Line__item">
                                    <div className="RT-Static-Field">
                                        <label className="RT-Static-Field__label">
                                            Automatic Expense Forwarding
                                            <CustomToolTip
                                                header="Automatic Expense Forwarding"
                                                bodyContent="When an Enhanced Benefit reaches the max limit, automatic expense forwarding allows any remaining unpaid amounts to be reimbursed, if eligible, under the main HSA or LSA benefit."
                                            />
                                        </label>
                                        <div
                                            className="RT-Static-Field__content">{data[i].allowFlowThrough ? "Yes" : "No"}</div>
                                    </div>
                                </div>}
                                {!data[i].nonTaxable ? <></> : 
                                <div className="RT-Line__item">
                                    <div className="RT-Static-Field">
                                        <label className="RT-Static-Field__label">
                                            Non Taxable
                                            <CustomToolTip
                                                header="Non Taxable"
                                                bodyContent=" All LSA expenses, including Enhanced Benefits, are taxable by default."
                                            />
                                        </label>
                                        <div
                                            className="RT-Static-Field__content">{data[i].nonTaxable ? "Yes" : "No"}</div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                );
            }
        }
    
        return categories;
    }

    render() {
        const { 
            loading,
            selectedAccountType,
            showEditCustomCategoryModal,
            selectedCustomCategory,
            showRemoveCustomCategoryModal,
        } = this.state;

        const editCustomCategoryModal = (
            <EditCustomCategoryModal
                show={showEditCustomCategoryModal}
                onHide={() => this.setState({ showEditCustomCategoryModal: false })}
                updateCustomCategory={this.updateCustomCategory}
                selectedCustomCategory={selectedCustomCategory}
            />
        );

        const removeCustomCategoryModal = (
            <RemoveCustomCategoryModal
                show={showRemoveCustomCategoryModal}
                onHide={() => this.setState({ showRemoveCustomCategoryModal: false })}
                removeCustomCategory={this.removeCustomCategory}
                selectedCustomCategory={selectedCustomCategory}
            />
        );

        let contents;
        if (loading) {
            contents = <CustomLoading />;
        } else {
            contents = this.renderCustomCategories();
        }

        let textHeader;
        let textStyle = {
            fontSize: 13,
            fontWeight: 500,
            paddingTop: 10,
            paddingBottom: 10,
            display: "block"
        };

        if(this.props.is_flex)
        {
            textHeader = (
                <span style={textStyle}>
                    Additional benefits that are dedicated to a specific expense, separate from the main category total, and excluded from regular Flex allocations. Renewed annually with no carryover. 
                    <br/>
                    After reaching the maximum, any remaining claim amounts submitted are reimbursed out of regular HSA or LSA benefits if eligible. 
                    <br/>
                    These totals are also subject to proration if it applies at the employee level. Up to 6 Enhanced Benefits allowed per HSA and LSA.
                </span>
            )
        } else {
            textHeader = (
                <span style={textStyle}>
                    Additional benefits that are dedicated to a specific expense, and separate from the main category total. 
                    <br/>
                    Renewed annually with no carryover. After reaching the maximum, any remaining claim amounts submitted are reimbursed out of regular HSA or LSA benefits if eligible. 
                    <br/>
                    These totals are also subject to proration if it applies at the employee level. Up to 6 Enhanced Benefits allowed per HSA and LSA.
                </span>
            )
        }

        return(
            <div>
                {textHeader}
                {contents}
                {editCustomCategoryModal}
                {removeCustomCategoryModal}
            </div>
        );
    }

}

export default CustomCategoriesScreen;

const EditCustomCategoryModal = ({updateCustomCategory, ...props}) => {
    const [accountType, setAccountType] = React.useState(props.selectedCustomCategory.accountType || "HSA" );
    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [limit, setLimit] = React.useState(0);
    const [allowDependentClaims, setAllowDependentClaims] = React.useState(props.selectedCustomCategory.allowDependentClaims || false);
    const [allowFlowThrough, setAllowFlowThrough] = React.useState(props.selectedCustomCategory.allowFlowThrough || false);

    React.useEffect(() => {
        if (props.selectedCustomCategory) {
            setName(props.selectedCustomCategory.name || "");
            setDescription(props.selectedCustomCategory.description || "");
            setLimit(props.selectedCustomCategory.limit || 0);
            setAllowDependentClaims(props.selectedCustomCategory.allowDependentClaims || false);
            setAllowFlowThrough(props.selectedCustomCategory.allowFlowThrough || false);
        }
    }, [props.selectedCustomCategory]);

    const handlelimitChange = (event) => {
        const newValue = event.target.value;
        if (newValue === '' || /^[0-9]*$/.test(newValue)) {
            setLimit(newValue);
        }
    }

    const handleSubmit = () => {
        updateCustomCategory(props.selectedCustomCategory, name, description, limit, allowDependentClaims, allowFlowThrough);
    }

    return(
        <Modal
            {...props}
            className="react-modal"
            keyboard={false}
            centered
            style={{ maxWidth: "510px", left: "40%" }}
        >
        <Modal.Header className="react-modal-title">
            <Modal.Title>
                Edit  {accountType} Enhanced Benefit
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Card>
                <Line>
                    <div className="RT-Line__item">
                        <div className="RT-Static-Field">
                            <label className="RT-Static-Field__label">Name</label>
                            <div className="RT-Static-Field__content">{name}</div>
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Line__item">
                        <div className="RT-Static-Field">
                            <label className="RT-Static-Field__label">Description</label>
                            <div className="RT-Static-Field__content">{description}</div>
                        </div>
                    </div>
                </Line>
                <div className="form-group">
                    <label htmlFor="limit">Limit</label>
                    <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="limit" 
                            value={limit}
                            onChange={handlelimitChange}
                        />
                    </div>
                </div>
                <Line>
                    <div className="RT-Line__item">
                        <div className="RT-Static-Field">
                            <label className="RT-Static-Field__label">Allow Dependent Claim</label>
                            <div className="RT-Static-Field__content">{allowDependentClaims ? "Yes" : "No"}</div>
                        </div>
                    </div>
                    {allowFlowThrough ? <></> : 
                        <div className="RT-Line__item">
                            <div className="RT-Static-Field">
                                <label className="RT-Static-Field__label">
                                    Automatic Expense Forwarding
                                    <CustomToolTip
                                        header="Automatic Expense Forwarding"
                                        bodyContent="When an Enhanced Benefit reaches the max limit, automatic expense forwarding allows any remaining unpaid amounts to be reimbursed, if eligible, under the main HSA or LSA benefit."
                                    />
                                </label>
                                <div
                                    className="RT-Static-Field__content">{allowFlowThrough ? "Yes" : "No"}</div>
                            </div>
                        </div>}
                </Line>
            </Card>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide} >Cancel</Button>
            <Button variant="primary" onClick={handleSubmit}>Save</Button>
        </Modal.Footer>
        </Modal>
    );
}

const RemoveCustomCategoryModal = ({removeCustomCategory, ...props}) => {
    const [accountType, setAccountType] = React.useState(props.selectedCustomCategory.accountType || "HSA" );
    const [name, setName] = React.useState(props.selectedCustomCategory.name || "");

    React.useEffect(() => {
        if (props.selectedCustomCategory) {
            setName(props.selectedCustomCategory.name || "");
        }
    }, [props.selectedCustomCategory]);

    const handleSubmit = () => {
        props.onHide();
        removeCustomCategory(props.selectedCustomCategory);
    }

    return(
        <Modal
            {...props}
            className="react-modal"
            keyboard={false}
            centered
            style={{ maxWidth: "500px", left: "40%" }}
        >
        <Modal.Header className="react-modal-title">
            <Modal.Title>
                Remove {accountType} Enhanced Benefit
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                Are you sure you want to remove {name}?
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide} >Cancel</Button>
            <Button variant="danger" onClick={handleSubmit} >Remove</Button>
        </Modal.Footer>
        </Modal>
    );
}