import React from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  TabSet,
  Tab,
  RecordHeader,
} from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Display, Line, Column, Panel, Button, Card } from "ktm-ui";
import Dependent_listScreen from "./Dependent_list";
import Date_range_listScreen from "./Date_range_list";
import EditScreen from "./Edit";
import TerminateScreen from "./Terminate";
import Edit_addressScreen from "./Edit_address";
import Edit_bencatScreen from "./Edit_bencat";
import CustomToolTip from "../../components/CustomToolTip";
import axios from "axios";
import BenefitEditScreen from "../employee_benefits/Edit";
import moment from "moment";
import { Modal } from "react-bootstrap";
import ReactButton from "react-bootstrap/Button";
import ReactMoment from "react-moment";
import BenefitEditFlexDatesScreen from "../employee_benefits/Edit_flex_dates";
import toastr from "toastr";
import CustomDisplayElement from "components/CustomDisplayElement";
import BenefitEditBenefitDatesScreen from "../employee_benefits/Edit_benefit_dates";
import BenefitEditEmployeeStartDateScreen from "../employee_benefits/Edit_employee_start_date";
import { CustomDropdown } from "components/CustomDropdown";
import Moment from "react-moment";
import { TeladocWallet } from "./TeladocWallet";
import { CoreHealthWallet } from "./CoreHealthWallet";

interface IdsEmployee {
  Id: string;
  FirstName: string;
  LastName: string;
  Name: string;
  Email: string;
  MaidenName: string;
  Nickname: string;
  EmployeeNo: string;
  DateOfBirth: string;
  HireDate: string;
  TerminationDate: string;
  TerminationNote: string;
  BenefitStartDate: string;
  Division_Id: string;
  CostCentre_Id: string;
  WaitingPeriodMonths: number;
  MailingStreet1: string;
  MailingStreet2: string;
  MailingCity: string;
  MailingProv: string;
  MailingCountry: string;
  MailingPostalCode: string;
  HomePhone: string;
  MobilePhone: string;
  BenefitCategory_Id: string;
  AccessAfterTerminationDays: number;
  IsActive: boolean;
  SalaryMoney: number;
  TeladocWaitingPeriodMonths: number;
  TeladocStartDate: string;
  IsPlanAdmin: boolean;
}

interface IdsPolicies {
  Id: string;
  CurrentBenYear_Id: string;
  IsBusinessOwner: boolean;
  HasTeladoc: boolean;
  EnableBonusCredit: boolean;
  PolicyNo: string;
  HasCorehealth: boolean;
  NatPolicy: string;
}

interface IdsPolicyHolder {
  Id: string;
  Name: string;
}

interface IdsPolicyBenefit {
  EffectiveDate: string;
  CarryFwdHSA: string;
  CarryFwdLSA: string;
}

interface IdsPolicyBenefitYear {
  Id: string;
  StartDate: string;
  EndDate: string;
}

interface IdsDivision {
  Id: string;
  Name: string;
  Description: string;
  FullName: string;
}

interface IdsCostCentre {
  Id: string;
  Name: string;
  Description: string;
  FullName: string;
}

interface IdsBenefitCategory {
  Id: string;
  Policy_Id: string;
  Name: string;
  Description: string;
  ExcludeTeladoc: boolean;
  ExcludeCorehealth: boolean;
}

interface IdsEmployeeYear {
  Id: string;
  BenefitYear_Id: string;
  FlexLaunchDate: string;
  FlexReminderDate: string;
  FlexLastDayReminderDate: string;
  FlexSelectionDeadline: string;
  FlexBase: number;
  IsFlexSelectionDefault: boolean;
  IsFlexSelectionDone: boolean;
  FlexChoiceMade: string;
  HSABalance: number;
  LSABalance: number;
  HSABase: number;
  LSABase: number;
  HSACarriedIn: number;
  LSACarriedIn: number;
  HSAMaxCarryFwd: number;
  LSAMaxCarryFwd: number;
  HSAProrated: number;
  LSAProrated: number;
  Coverage: string;
  HasProrating: boolean;
  HSABonusCredit: number;
  LSABonusCredit: number;
}

interface IdsPreviousEmployeeBenefits {
  Id: string;
  BenefitYear_Id: string;
  BenefitName: string;
  BenefitHasHSA: boolean;
  BenefitHasLSA: boolean;
  BenefitCategoryName: string;
  Status: string;
  Coverage: string;
  HasProrating: boolean;
  HSABase: number;
  LSABase: number;
  HSALimitOverride: number;
  LSALimitOverride: number;
  HSAProrated: number;
  LSAProrated: number;
  FlexProrated: number;
  RSPChoiceAmount: number;
  FlexBase: number;
  HasHSA: boolean;
  HasLSA: boolean;
  HasFlex: boolean;
  HasRSP: boolean;
  TFSAChoiceAmount: number;
  HasTFSA: boolean;
}

interface IdsPlanAdmins {
  Id: string;
  UserId: string;
}

interface IdsPolicyBenefitLimits {
  HasHSA: boolean;
  HasLSA: boolean;
  HSASingleLimit: number;
  HSACoupleLimit: number;
  HSAFamilyLimit: number;
  HasFlex: boolean;
  HasRsp: boolean;
  CreditCalcMethod: string;
  FlexSinglePercent: number;
  FlexCouplePercent: number;
  FlexFamilyPercent: number;
  HasTFSA: boolean;
}

const TeladocWaitingPeriodMonths = [
  { value: 0, label: "None" },
  { value: 1, label: "1 month" },
  { value: 2, label: "2 months" },
  { value: 3, label: "3 months" },
  { value: 4, label: "4 months" },
  { value: 5, label: "5 months" },
  { value: 6, label: "6 months" },
  { value: 7, label: "7 months" },
  { value: 8, label: "8 months" },
  { value: 9, label: "9 months" },
  { value: 10, label: "10 months" },
  { value: 11, label: "11 months" },
  { value: 12, label: "12 months" },
];

export default KTMScreen(
  { name: "planadmin.employee.record" },
  class extends React.Component<{ employee_id: string }> {
    data: {
      dsEmployee: IdsEmployee;
      dsDivision: IdsDivision;
      dsCostCentre: IdsCostCentre;
      dsBenefitCategory: IdsBenefitCategory;
      dsEmployeeYear: IdsEmployeeYear;
      dsPreviousEmployeeBenefits: IdsPreviousEmployeeBenefits;
      dsPolicies: IdsPolicies;
      dsPolicyBenefit: IdsPolicyBenefit;
      dsPolicyHolder: IdsPolicyHolder;
      dsPolicyBenefitYear: IdsPolicyBenefitYear;
      dsPlanAdmins: IdsPlanAdmins;
      dsPolicyBenefitLimits: IdsPolicyBenefitLimits;
    };

    state = {
      terminationLog: [],
      showTerminationDeleteModal: false,
      enableBenefitDateEdit: false,
      showReinstateModal: false,
      reinstateNote: "",
      hasHsa: false,
      hasLsa: false,
      hsaAdjustment: null,
      lsaAdjustment: null,
      prevYearHasHsa: false,
      prevYearHasLsa: false,
      prevYearHSAAdjustment: null,
      prevYearLSAAdjustment: null,
      showDateRangeModal: false,
      isStartDateValid: false,
      employeeCustomCategories: null,
      employeePrevCustomCategories: null,
      isAllowBenefitCategoryEdit: false,
      showCustomCategoryModal: false,
      editableCategories: null,
      isEligible: false,
      showAcknowledgmentModal: false,
      FlexTotalSelected: 0,
      HsaSelected: 0,
      LsaSelected: 0,
      showEditTeladocModal: false,
      teladocWaitingPeriod: 0,
      teladocStartDate: null,
      selectedTeladocWaitingPeriod: TeladocWaitingPeriodMonths[0],
      hsaBonusCredit: null,
      lsaBonusCredit: null,
      prevYearHsaBonusCredit: null,
      prevYearLsaBonusCredit: null,
      showBonusCreditModal: false,
      selectedBonusCreditType: "HSA",
      currentBonusCredit: 0.0,
      inputBonusCredit: 0.0,
      inputBonusCreditNote: "",
      hasNegativeAdjustments: false,
      inRunoff: false,
      chInfo: null,
    };

    render() {
      return renderScreen(this);
    }

    componentDidMount() {
      this.getTerminationLog();
      this.getAdjustments();
      this.getPreviousYearAdjustments();
      this.getEmployeeCustomCategories();
      this.getEmployeePrevCustomCategories();
      this.getFlexSelection();
      this.getTeladoc();
      this.getBonusCredit();
      this.getPreviousYearBonusCredit();
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsEmployee";
      await KTMApi.start(screen, {
        "@employee_id": this.props.employee_id,
      });
      screen.title =
        screen.getValue("FirstName") + " " + screen.getValue("LastName");

      this.checkFirstYearCoverage();
      this.checkEmployeeStartDate();
      this.getEmployeeCustomCategories();
      this.getEmployeePrevCustomCategories();
      this.checkIsAllowBenefitCategoryEdit();
      this.getFlexSelection();
      this.getTeladoc();
      this.checkEmployeeNegativeAdjustment();
      this.isPolicyInRunoff();
      this.getEmployeeCHInfo();
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.tabset)}
        </Layout>
      );
    }

    header({ field, draw, action, getValue }: RenderEvent) {
      return (
        <RecordHeader entityLabel="Employee" label={getValue("Name")}>
          <Line>
            <Display field={field("Email")} textStyle="bold" />
            <Display
              field={field("@dsBenefitCategory.Name")}
              textStyle="bold"
            />
            <Display field={field("DateOfBirth")} />
            <Display field={field("HireDate")} />
            {this.data.dsEmployee.IsPlanAdmin ? (
              <p style={{ color: "blue" }}>
                This employee is also assigned as a Plan Administrator.
              </p>
            ) : (
              <></>
            )}
          </Line>
        </RecordHeader>
      );
    }

    tabset({ field, draw, action }: RenderEvent) {
      const { hsaAdjustment, lsaAdjustment } = this.state;
      const hasLimitAdjustments =
        hsaAdjustment?.hasAdjustment || lsaAdjustment?.hasAdjustment;

      return (
        <TabSet>
          <Tab label="Details" template={this.main_tab} />
          <Tab label="Dependents" template={this.dependents} />
          <Tab label="Benefit Limits" template={this.benefits} />
          <Tab label="Date Ranges" template={this.date_ranges} />
          {hasLimitAdjustments ? (
            <Tab label="Limit Adjustments" template={this.limit_adjustments} />
          ) : (
            <></>
          )}
          {this.data.dsPolicies.EnableBonusCredit ? (
            <Tab label="Bonus Credit" template={this.bonus_credit} />
          ) : (
            <></>
          )}
          {/*{this.data.dsPolicies.HasTeladoc ? <Tab label="Billing" template={this.billing} /> : <></>}*/}
          <Tab label="Terminations" template={this.terminations} />
          {this.data.dsPolicies.HasTeladoc &&
          !this.data.dsBenefitCategory.ExcludeTeladoc ? (
            <Tab label="Teladoc" template={this.teladoc_tab} />
          ) : (
            <></>
          )}
          {this.data.dsPolicies.HasCorehealth &&
          !this.data.dsBenefitCategory.ExcludeCorehealth ? (
            <Tab
              label={
                this.data.dsPolicies.NatPolicy === "NAT1001"
                  ? "CoreHealth"
                  : "InsurPak"
              }
              template={this.corehealth_tab}
            />
          ) : (
            <></>
          )}
        </TabSet>
      );
    }

    corehealth_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            <Card label="Documents">
              <Line>
                <div>
                  <CoreHealthWallet
                    policyNo={this.data.dsPolicies.PolicyNo}
                    employeeName={
                      this.data.dsEmployee.FirstName +
                      " " +
                      this.data.dsEmployee.LastName
                    }
                    natPolicy={this.data.dsPolicies.NatPolicy}
                    chCoverage={this.state.chInfo.employeeCoverage || ""}
                    chBenefitPeriod={
                      this.data.dsPolicyBenefitYear.StartDate +
                      " - " +
                      this.data.dsPolicyBenefitYear.EndDate
                    }
                    chApproved={this.state.chInfo.chApproved}
                    chPending={this.state.chInfo.chPending}
                    chLimit={this.data.dsEmployeeYear.HSABase}
                    chBalance={this.data.dsEmployeeYear.HSABase}
                  />
                </div>
              </Line>
            </Card>
          </Column>
        </Panel>
      );
    }

    teladoc_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.teladoc_details)}</Column>
        </Panel>
      );
    }

    teladoc_details({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            <Card label="Documents">
              <Line>
                <div>
                  <TeladocWallet
                    policyNo={this.data.dsPolicies.PolicyNo}
                    employeeName={
                      this.data.dsEmployee.FirstName +
                      " " +
                      this.data.dsEmployee.LastName
                    }
                  />
                </div>
              </Line>
            </Card>
          </Column>
        </Panel>
      );
    }

    terminations({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.termination_details)}
            {draw(this.termination_status)}
          </Column>
        </Panel>
      );
    }

    billing({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.teladoc)}
          </Column>
        </Panel>
      );
    }

    teladoc({ field, draw, action }: RenderEvent) {
      let editTeladocModal = null;
      if (this.state.showEditTeladocModal) {
        editTeladocModal = (
          <Modal
            className="react-modal react-modal-confirm"
            backdrop="static"
            show={this.state.showEditTeladocModal}
            centered
          >
            <Modal.Header  className="react-modal-title">
              <Modal.Title>Edit Teladoc</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="RT-Section">
                <div className="RT-Line">
                  <div className="employee-edit-bencat">
                    <div className="RT-TextBox edit-employee-ben-cat">
                      <label className="RT-Label">
                        Teladoc Waiting Period (months)
                      </label>
                      <CustomDropdown
                        options={TeladocWaitingPeriodMonths.map((option) => {
                          return option.label;
                        })}
                        selectedOption={this.state.selectedTeladocWaitingPeriod.label}
                        setSelectedOption={this.setSelectedTeladocWaitingPeriod}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ReactButton 
                  variant="secondary" 
                  onClick={this.hideEditTeladocModal}
                >
                  Cancel
              </ReactButton>
              <Button
                action={action(this.submitTeladocEdit)}
                label="Save"
                size="large"
                style="primary"
              ></Button>
            </Modal.Footer>
          </Modal>
        );
      }

      return (
        <>
          {editTeladocModal}
          <div className="RT-Section">
            <div className="RT-Section__header">
              <div className="RT-Section__title">Teladoc</div>
              {/*<div className="RT-Section__actions">*/}
              {/*  <a*/}
              {/*    href="#"*/}
              {/*    className="RT-Link"*/}
              {/*    onClick={() => this.showEditTeladocModal()}*/}
              {/*  >*/}
              {/*    Edit*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
            <div className="RT-Line">
              <div className="RT-Line__content RT-Line__content--space-evenly">
                <div className="RT-Line__item">
                  <div className="RT-Static-Field">
                    <div className="RT-Static-Field__label">
                      Benefit Start Date (YYYY-MM-DD)
                      <CustomToolTip
                          header="Benefit Start Date"
                          bodyContent="Date when Teladoc benefits begin. If left blank, 
                              the Benefit Start Date or Hire Date + Policy Teladoc waiting period will be used."
                      />
                    </div>
                    <div
                      className="RT-Static-Field__content"
                      style={{ marginTop: 10 }}
                    >
                      {this.state.teladocStartDate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.general)}
            {draw(this.contact_info)}
            {draw(this.reporting)}
          </Column>
        </Panel>
      );
    }

    dependents({ field, draw, action }: RenderEvent) {
      return <Dependent_listScreen employee_id={this.data.dsEmployee.Id} />;
    }

    date_ranges({ field, draw, action }: RenderEvent) {
      return <Date_range_listScreen employee_id={this.data.dsEmployee.Id} />;
    }

    limit_adjustments({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.limit_adjustment_details)}
            {draw(this.limit_adjustment_history)}
            {this.data.dsPreviousEmployeeBenefits.Status == "OPEN" &&
              draw(this.limit_adjustment_previous_year_history)}
          </Column>
        </Panel>
      );
    }

    bonus_credit({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.bonus_credit_history)}
            {this.data.dsPreviousEmployeeBenefits.Status == "OPEN" &&
              draw(this.bonus_credit_previous_year_history)}
          </Column>
        </Panel>
      );
    }

    benefits({ field, draw, action }: RenderEvent) {
      const createAdjustmentDescription = (description) => (
        <>
          <label style={{ paddingBottom: 15 }}>
            {description}
          </label>
          <br />
        </>
      );
    
      const adjDesc = createAdjustmentDescription(
        "This limit has a manual adjustment made to it. For full details, review the information under this employee's 'Limit Adjustment' tab."
      );
    
      const bonusCreditDesc = createAdjustmentDescription(
        "This limit has a bonus credit made to it. For full details, review the information under this employee's 'Bonus Credit' tab."
      );
    
      // Helper function to determine HSA or LSA limit based on coverage
      const getFixedLimit = (coverage, limits) => {
        switch (coverage) {
          case "SINGLE":
            return limits.HSASingleLimit || limits.LSASingleLimit;
          case "COUPLE":
            return limits.HSACoupleLimit || limits.LSACoupleLimit;
          case "FAMILY":
            return limits.HSAFamilyLimit || limits.LSAFamilyLimit;
          default:
            return 0;
        }
      };

      // Helper function to render Limit Breakdown
      const renderLimitBreakdown = (
        base,
        categoryLimit,
        carriedIn,
        bonusCredit,
        adjustedValue
      ) => {
        const total =
          categoryLimit + (adjustedValue || 0) + carriedIn + (bonusCredit || 0);
        return (
          <label style={{ paddingBottom: 15 }}>
            ${total} = ${categoryLimit} (Benefit Category){" "}
            {adjustedValue === 0 ? "" : ` + $${adjustedValue} (Adjusted Value)`}{" "}
            + ${carriedIn} (Carried In){" "}
            {bonusCredit ? ` + $${bonusCredit} (Bonus Credit)` : ""}
          </label>
        );
      };

      // Helper function to create Limit display
      const createLimitDisplay = (
        labelText,
        adjustmentCheck,
        proratedField,
        balance,
        showBonusCredit,
        showMaxCarryFwd,
        carryForward
      ) => {
        if (adjustmentCheck) {
          return (
            <>
              <Line>
                <Display field={field(proratedField)} />
                <Display field={field(balance)} />
                <div className="RT-Static-Field">
                  <label className="RT-Static-Field__label">{labelText}</label>
                  <div className="RT-Static-Field__content">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {this.renderStaticCheckmark()}
                      <div>Yes</div>
                    </div>
                  </div>
                </div>
                {showMaxCarryFwd ? (
                  <Display field={field(carryForward)} />
                ) : (
                  <></>
                )}
              </Line>
              {adjDesc}
              {showBonusCredit && bonusCreditDesc}
            </>
          );
        }
        return (
          <>
            <Line>
              <Display field={field(proratedField)} />
              <Display field={field(balance)} />
              {showMaxCarryFwd ? (
                <Display field={field(carryForward)} />
              ) : (
                <></>
              )}
            </Line>
            {showBonusCredit && bonusCreditDesc}
          </>
        );
      };

      let limits = (
        <Line>
          <span style={{ marginTop: 20, fontWeight: "bold" }}>
            No Spending Account Benefit Assigned.
          </span>
        </Line>
      );

      let systemBenefitStartDate = this.systemGeneratedBenefitStartDate();

      const hsaAdjustedValues = (
        (this.state.hsaAdjustment &&
          this.state.hsaAdjustment.employeeCurrentYearLedger) ||
        []
      )
        .map((entry) => entry.credit)
        .filter((credit) => credit != null)
        .reduce((total, credit) => total + credit, 0); // Calculate the total of all credit values

      // Determine HSA limit breakdown
      let hsaLimitBreakdown = null;
      if (
        this.data.dsEmployeeYear.HSACarriedIn > 0 &&
        this.data.dsPolicyBenefitLimits.HasHSA
      ) {
        const fixedHSALimit = getFixedLimit(
          this.data.dsEmployeeYear.Coverage,
          this.data.dsPolicyBenefitLimits
        );
        const hsaCategoryLimit = this.data.dsPolicyBenefitLimits.HasFlex
          ? this.state.HsaSelected
          : fixedHSALimit;
        hsaLimitBreakdown = renderLimitBreakdown(
          this.data.dsEmployeeYear.HSABase,
          hsaCategoryLimit,
          this.data.dsEmployeeYear.HSACarriedIn,
          this.data.dsEmployeeYear.HSABonusCredit,
          hsaAdjustedValues
        );
      }

      // Determine LSA limit breakdown
      let lsaLimitBreakdown = null;
      if (
        this.data.dsEmployeeYear.LSACarriedIn > 0 &&
        this.data.dsPolicyBenefitLimits.HasLSA
      ) {
        const fixedLSALimit = getFixedLimit(
          this.data.dsEmployeeYear.Coverage,
          this.data.dsPolicyBenefitLimits
        );
        const lsaCategoryLimit = this.data.dsPolicyBenefitLimits.HasFlex
          ? this.state.HsaSelected
          : fixedLSALimit;

        const lsaAdjustedValues = (
          (this.state.lsaAdjustment &&
            this.state.lsaAdjustment.employeeCurrentYearLedger) ||
          []
        )
          .map((entry) => entry.credit)
          .filter((credit) => credit != null)
          .reduce((total, credit) => total + credit, 0); // Calculate the total of all credit values

        lsaLimitBreakdown = renderLimitBreakdown(
          this.data.dsEmployeeYear.LSABase,
          lsaCategoryLimit,
          this.data.dsEmployeeYear.LSACarriedIn,
          this.data.dsEmployeeYear.LSABonusCredit,
          lsaAdjustedValues
        );
      }

      // RSP limit display
      let rspLimit = null;
      if (this.data.dsPolicyBenefitLimits.HasRsp) {
        rspLimit = (
          <Line>
            <Display field={field("@dsEmployeeYear.RSPChoiceAmount")} />
            <Display field={field("@dsPolicyBenefitLimits.HasRsp")} />
          </Line>
        );
      }
    
      // TFSA limit display
      let TFSALimit = null;
      if (this.data.dsPolicyBenefitLimits.HasTFSA) {
        TFSALimit = (
          <Line>
            <Display field={field("@dsEmployeeYear.TFSAChoiceAmount")} />
            <Display field={field("@dsPolicyBenefitLimits.HasTFSA")} />
          </Line>
        );
      }

      // Define HSA and LSA Prorated Limits
      let HSAProratedLimit = null;
      let LSAProratedLimit = null;
    
      if (this.data.dsEmployeeYear.HasProrating) {
        if (this.data.dsPolicyBenefitLimits.HasHSA) {
          HSAProratedLimit = createLimitDisplay(
            "Has HSA Limit Adjustment",
            this.state.hsaAdjustment != null &&
              this.state.hsaAdjustment.employeeCurrentYearLedger != null &&
              this.state.hsaAdjustment.employeeCurrentYearLedger.length > 0,
            "@dsEmployeeYear.HSAProrated",
              "@dsEmployeeYear.HSABalance",
            this.data.dsEmployeeYear.HSABonusCredit > 0,
              this.data.dsPolicyBenefit.CarryFwdHSA === "CCF" && !this.state.inRunoff,
              "@dsEmployeeYear.HSAMaxCarryFwd",
          );
        }
    
        if (this.data.dsPolicyBenefitLimits.HasLSA) {
          LSAProratedLimit = createLimitDisplay(
            "Has LSA Limit Adjustment",
            this.state.lsaAdjustment != null &&
              this.state.lsaAdjustment.employeeCurrentYearLedger != null &&
              this.state.lsaAdjustment.employeeCurrentYearLedger.length > 0,
            "@dsEmployeeYear.LSAProrated",
              "@dsEmployeeYear.LSABalance",
            this.data.dsEmployeeYear.LSABonusCredit > 0,
              this.data.dsPolicyBenefit.CarryFwdLSA === "CCF" && !this.state.inRunoff,
              "@dsEmployeeYear.LSAMaxCarryFwd",
          );
        }
    
        limits = (
          <>
            {this.data.dsPolicyBenefitLimits.HasHSA ?
              <Card>
                <div className="hsaCard">Health Spending Account</div>
                <div style={{padding: "10px"}}>
                  {HSAProratedLimit}
                </div>
              </Card> : <></>}
            {this.data.dsPolicyBenefitLimits.HasLSA ?
              <Card>
                <div className="lsaCard">Lifestyle Spending Account</div>
                <div style={{padding: "10px"}}>
                  {LSAProratedLimit}
                </div>
              </Card> : <></>}
            <Card>
              {rspLimit}
            </Card>
            <Card>
              {TFSALimit}
            </Card>
          </>
        );
      } else {
        const renderFamilyOrIndividualLimits = (
          coverage, 
          hsaAdjustmentCheck, 
          lsaAdjustmentCheck
        ) => {
          let hsaLimit = null;
          let lsaLimit = null;
    
          if (this.data.dsPolicyBenefitLimits.HasHSA) {
            hsaLimit = createLimitDisplay(
              "Has HSA Limit Adjustment",
              hsaAdjustmentCheck,
              "@dsEmployeeYear.HSABase",
                "@dsEmployeeYear.HSABalance",
              this.data.dsEmployeeYear.HSABonusCredit > 0,
                this.data.dsPolicyBenefit.CarryFwdHSA === "CCF" && !this.state.inRunoff,
            "@dsEmployeeYear.HSAMaxCarryFwd",
            );
          }
    
          if (this.data.dsPolicyBenefitLimits.HasLSA) {
            lsaLimit = createLimitDisplay(
              "Has LSA Limit Adjustment",
              lsaAdjustmentCheck,
              "@dsEmployeeYear.LSABase",
                "@dsEmployeeYear.LSABalance",
              this.data.dsEmployeeYear.LSABonusCredit > 0,
                this.data.dsPolicyBenefit.CarryFwdLSA === "CCF" && !this.state.inRunoff,
                "@dsEmployeeYear.LSAMaxCarryFwd",
            );
          }
    
          return (
            <>
              {this.data.dsPolicyBenefitLimits.HasHSA ? (
                <Card>
                  <div className="hsaCard">Health Spending Account</div>
                  <div style={{ padding: "10px" }}>
                    {hsaLimit}
                    {hsaLimitBreakdown}
                    {this.data.dsPreviousEmployeeBenefits.Status === "OPEN" && draw(this.previous_hsa_year_benefit_amounts)}
                    {this.data.dsPreviousEmployeeBenefits.Status === "CLOSED" && draw(this.carry_in_hsa_amounts)}
                  </div>
                </Card>
              ) : (
                <></>
              )}
              {this.data.dsPolicyBenefitLimits.HasLSA ? (
                <Card>
                  <div className="lsaCard">Lifestyle Spending Account</div>
                  <div style={{ padding: "10px" }}>
                    {lsaLimit}
                    {lsaLimitBreakdown}
                    {this.data.dsPreviousEmployeeBenefits.Status === "OPEN" && draw(this.previous_lsa_year_benefit_amounts)}
                    {this.data.dsPreviousEmployeeBenefits.Status === "CLOSED" && draw(this.carry_in_lsa_amounts)}
                  </div>
                </Card>
              ) : (
                <></>
              )}
              <Card>
                {rspLimit}
              </Card>
              <Card>
                {TFSALimit}
              </Card>
            </>
          );
        };
    
        if (this.data.dsEmployeeYear.Coverage === "FAMILY") {
          limits = renderFamilyOrIndividualLimits(
            "FAMILY",
            this.state.hsaAdjustment != null &&
              this.state.hsaAdjustment.employeeCurrentYearLedger != null &&
              this.state.hsaAdjustment.employeeCurrentYearLedger.length > 0,
            this.state.lsaAdjustment != null &&
              this.state.lsaAdjustment.employeeCurrentYearLedger != null &&
              this.state.lsaAdjustment.employeeCurrentYearLedger.length > 0
          );
        } else if (this.data.dsEmployeeYear.Coverage === "SINGLE") {
          limits = renderFamilyOrIndividualLimits(
            "SINGLE",
            this.state.hsaAdjustment != null &&
              this.state.hsaAdjustment.employeeCurrentYearLedger != null &&
              this.state.hsaAdjustment.employeeCurrentYearLedger.length > 0,
            this.state.lsaAdjustment != null &&
              this.state.lsaAdjustment.employeeCurrentYearLedger != null &&
              this.state.lsaAdjustment.employeeCurrentYearLedger.length > 0
          );
        } else if (this.data.dsEmployeeYear.Coverage === "COUPLE") {
          limits = renderFamilyOrIndividualLimits(
            "COUPLE",
            this.state.hsaAdjustment != null &&
              this.state.hsaAdjustment.employeeCurrentYearLedger != null &&
              this.state.hsaAdjustment.employeeCurrentYearLedger.length > 0,
            this.state.lsaAdjustment != null &&
              this.state.lsaAdjustment.employeeCurrentYearLedger != null &&
              this.state.lsaAdjustment.employeeCurrentYearLedger.length > 0
          );
        }
      }
    
      // Flex benefits
      let flexTotal = null;
      let notificationDates = null;
      let flexSelection = null;
      let isEligibleToResendFlex = false;
    
      if (this.data.dsPolicyBenefitLimits.HasFlex) {
        let calcFormula = null;
        if (this.data.dsPolicyBenefitLimits.CreditCalcMethod === "SALARY") {
          const getFlexPercentage = (coverage) => {
            switch (coverage) {
              case "SINGLE":
                return this.data.dsPolicyBenefitLimits.FlexSinglePercent;
              case "COUPLE":
                return this.data.dsPolicyBenefitLimits.FlexCouplePercent;
              case "FAMILY":
                return this.data.dsPolicyBenefitLimits.FlexFamilyPercent;
              default:
                return 0;
            }
          };

          const bencatPercent = getFlexPercentage(
            this.data.dsEmployeeYear.Coverage
          );

          const proratedMonthsPart = this.data.dsEmployeeYear.HasProrating
            ? `X ${this.computeProratedMonths(
                this.data.dsPolicyBenefitYear.StartDate,
                this.data.dsPolicyBenefitYear.EndDate,
                this.data.dsPolicyBenefit.EffectiveDate,
                this.data.dsEmployee.HireDate,
                this.data.dsEmployee.WaitingPeriodMonths
              )}/12 (Prorated Months)`
            : "";

          calcFormula = (
            <label style={{ paddingBottom: 15 }}>
              ${this.data.dsEmployeeYear.FlexBase} = $
              {field("@dsEmployee.SalaryMoney").record.SalaryMoney} (Salary) X{" "}
              {bencatPercent}% (Benefit Category) {proratedMonthsPart}
            </label>
          );
        }

        flexTotal = (
          <Card>
            <Line>
              <Display field={field("@dsEmployeeYear.FlexBase")} />
              <Display
                field={field("@dsPolicyBenefitLimits.CreditCalcMethod")}
              />
            </Line>
            {calcFormula}
          </Card>
        );

        const hasPassedDeadline = moment().isAfter(
          moment(this.data.dsEmployeeYear.FlexSelectionDeadline).add(1, "day")
        );

        const hasPassedLaunchDate = moment().isAfter(
          moment(this.data.dsEmployeeYear.FlexLaunchDate)
        );

        isEligibleToResendFlex = !hasPassedDeadline && hasPassedLaunchDate;

        if (this.state.isStartDateValid) {
          notificationDates = (
            <>
              <Card
                label="Notification Dates"
                actions={
                  this.state.isAllowBenefitCategoryEdit &&
                  (!this.data.dsEmployeeYear.IsFlexSelectionDone ||
                    !hasPassedDeadline) && (
                    <Button
                      action={action(this.edit_flex_dates)}
                      label="Edit"
                      style="link"
                    />
                  )
                }
              >
                <Line>
                  <Display field={field("@dsEmployeeYear.FlexLaunchDate")} />
                  <Display field={field("@dsEmployeeYear.FlexReminderDate")} />
                </Line>
                <Line>
                  <Display
                    field={field("@dsEmployeeYear.FlexLastDayReminderDate")}
                  />
                  <Display
                    field={field("@dsEmployeeYear.FlexSelectionDeadline")}
                  />
                </Line>
                <Line>
                  <Display field={field("@dsEmployeeYear.EnableFlexEmails")} />
                </Line>
              </Card>
              {isEligibleToResendFlex && (
                <button
                  onClick={this.resendFlexEmail}
                  className="RT-Button RT-Button--primary"
                  style={{ marginTop: "1rem" }}
                >
                  Resend Flex Email
                </button>
              )}
            </>
          );
        }

        if (this.data.dsEmployeeYear.IsFlexSelectionDone) {
          const allocatedDateUtc = moment.utc(
            this.data.dsEmployeeYear.FlexChoiceMade
          );
          const allocatedDate = moment(allocatedDateUtc).local();
          flexSelection = (
            <Card>
              <Line>
                <div className="RT-Static-Field">
                  <label className="RT-Static-Field__label">
                    Flex Selection
                  </label>
                  <div className="RT-Static-Field__content">
                    {this.data.dsEmployeeYear.IsFlexSelectionDefault
                      ? "Default"
                      : "Allocated"}
                  </div>
                </div>
                <div className="RT-Static-Field">
                  <label className="RT-Static-Field__label">
                    Flex Last Activity
                  </label>
                  <div className="RT-Static-Field__content">
                    {allocatedDate.format("YYYY-MM-DD hh:mm A")}
                  </div>
                </div>
              </Line>
            </Card>
          );
        }
      }

      const {
        dsEmployee,
        dsEmployeeYear,
        dsPolicyBenefit,
        dsPolicyBenefitYear,
      } = this.data;
      const isEligibleToResendWelcomeEmail =
        dsEmployee.IsActive &&
        dsEmployee.BenefitStartDate &&
        moment(dsEmployee.BenefitStartDate).isSameOrBefore(moment()) &&
        dsPolicyBenefitYear.StartDate &&
        moment(dsPolicyBenefitYear.StartDate).isSameOrBefore(moment()) &&
        dsPolicyBenefit.EffectiveDate &&
        moment(dsPolicyBenefit.EffectiveDate).isSameOrBefore(moment()) &&
        (dsEmployeeYear.HSABalance > 0 ||
          dsEmployeeYear.LSABalance > 0 ||
          this.state.employeeCustomCategories?.length > 0);

      const resendWelcomeBtnStyle = isEligibleToResendFlex
        ? { marginTop: "1rem", marginLeft: "20px" }
        : { marginTop: "1rem" };

      let balance = null;
      if (
        this.data.dsPolicyBenefitLimits.HasHSA &&
        this.data.dsPolicyBenefitLimits.HasLSA
      ) {
        balance = (
          <Card>
            <Line>
              <Display field={field("@dsEmployeeYear.HSABalance")} />
              <Display field={field("@dsEmployeeYear.LSABalance")} />
            </Line>
          </Card>
        );
      } else if (this.data.dsPolicyBenefitLimits.HasHSA) {
        balance = (
          <Card>
            <Line>
              <Display field={field("@dsEmployeeYear.HSABalance")} />
            </Line>
          </Card>
        );
      } else if (this.data.dsPolicyBenefitLimits.HasLSA) {
        balance = (
          <Card>
            <Line>
              <Display field={field("@dsEmployeeYear.LSABalance")} />
            </Line>
          </Card>
        );
      }

      const dateRangeModal = (
        <Modal
          show={this.state.showDateRangeModal}
          style={{ top: 150 }}
          centered
        >
          <Modal.Header>
            <Modal.Title style={{ fontSize: "17px" }}>
              Employee Date Range
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              There is an open-ended inactive date for this employee. Please
              update the "Date Ranges" section.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={() => this.setState({ showDateRangeModal: false })}
            >
              Close
            </ReactButton>
          </Modal.Footer>
        </Modal>
      );

      let isPlanAdmin = false;
      if (
        this.data.dsPlanAdmins.UserId &&
        dsEmployeeYear.HSABase === 0 &&
        dsEmployeeYear.LSABase === 0 &&
        dsEmployeeYear.FlexBase === 0
      ) {
        isPlanAdmin = true;
        limits = null;
        balance = null;
      }

      const allDateEdit =
        this.state.isAllowBenefitCategoryEdit &&
        this.state.enableBenefitDateEdit;
      return (
        <>
          {dateRangeModal}
          <Card
            label="Benefit Date Setup"
            actions={
              <>
                {allDateEdit && (
                  <Button
                    action={action(this.edit_benefitDates)}
                    label="Edit"
                    style="link"
                  />
                )}
              </>
            }
          >
            <Line>
              <Display field={field("HireDate")} />
              <Display
                field={field("WaitingPeriodMonths")}
                toolTip={
                  <CustomToolTip
                    header="Waiting Period (months)"
                    bodyContent="Choose how long an employee waits after being hired for their benefits to start."
                  />
                }
              />
              <Display field={field("@dsEmployeeYear.HasProrating")} />
            </Line>
          </Card>
          <Card
            label="Employee Start Date"
            actions={
              <>
                {allDateEdit && (
                  <Button
                    action={action(this.edit_startDate)}
                    label="Edit"
                    style="link"
                  />
                )}
              </>
            }
          >
            <Line>
              <div className="RT-Static-Field">
                <div className="RT-Static-Field__label">
                  <label>
                    System Calculated Benefit Start Date (YYYY-MM-DD)
                  </label>
                </div>
                <div className="RT-Static-Field__content">
                  {systemBenefitStartDate}
                </div>
              </div>
              <Display
                label="PA Approved Benefit Start Date (YYYY-MM-DD)"
                field={field("@dsEmployee.BenefitStartDate")}
              />
              <Display field={field("@dsEmployee.IsStartDateAdjusted")} />
            </Line>
          </Card>
          <Card
            label="Category"
            actions={
              this.state.isAllowBenefitCategoryEdit && (
                <Button
                  action={action(this.edit_benefits)}
                  label="Edit"
                  style="link"
                />
              )
            }
          >
            <Line>
              <Display field={field("@dsPolicyBenefit.Name")} />
            </Line>
            <Line>
              <Display field={field("@dsBenefitCategory.Name")} />
              {!isPlanAdmin ? (
                <Display field={field("@dsEmployeeYear.Coverage")} />
              ) : (
                <></>
              )}
            </Line>
            {this.data.dsPolicyBenefitLimits.CreditCalcMethod === "SALARY" && (
              <Line>
                <Display field={field("@dsEmployee.SalaryMoney")} />
              </Line>
            )}
          </Card>
          {flexTotal}
          {limits}
          {this.state.employeeCustomCategories != null &&
            this.state.employeeCustomCategories.length > 0 &&
            draw(this.custom_category)}
          {/* {this.data.dsPreviousEmployeeBenefits.Status === "CLOSED" &&
            draw(this.carry_in_amounts)} */}
          {this.state.employeePrevCustomCategories != null &&
            this.state.employeePrevCustomCategories.length > 0 &&
            draw(this.previous_year_custom_category)}
          {this.data.dsPreviousEmployeeBenefits.Status === "OPEN" &&
            draw(this.previous_year_benefit_details)}
          {this.data.dsPreviousEmployeeBenefits.Status === "OPEN" &&
            draw(this.previous_year_benefit_flex_total)}
          {this.data.dsPreviousEmployeeBenefits.Status === "OPEN" &&
            draw(this.previous_year_benefit_amounts)}
          {flexSelection}
          {notificationDates}
          {isEligibleToResendWelcomeEmail && (
            <button
              onClick={this.resendEmployeeWelcomeEmail}
              className="RT-Button RT-Button--primary"
              style={resendWelcomeBtnStyle}
            >
              Resend Welcome Email
            </button>
          )}
        </>
      );
    }

    previous_year_benefit_flex_total({ field, draw, action }: RenderEvent) {
      let flexTotal = null;
      if (this.data.dsPreviousEmployeeBenefits.HasFlex === true) {
        flexTotal = (
          <Card background="previous-year-section">
            <Line>
              <Display field={field("@dsPreviousEmployeeBenefits.FlexBase")} />
              <Display
                field={field("@dsPreviousEmployeeBenefits.CreditCalcMethod")}
              />
            </Line>
          </Card>
        );
      }

      return <>{flexTotal}</>;
    }

    carry_in_amounts({ field, draw, action }: RenderEvent) {
      return (
        <Card background="previous-year-section">
          <Line>
            <Display field={field("@dsEmployeeYear.HSACarriedIn")} />
            <Display field={field("@dsEmployeeYear.LSACarriedIn")} />
          </Line>
        </Card>
      );
    }

    carry_in_hsa_amounts({ field, draw, action }: RenderEvent) {
      return (
        <Card background="previous-year-section">
          <Line>
            <Display field={field("@dsEmployeeYear.HSACarriedIn")} />
          </Line>
        </Card>
      );
    }

    carry_in_lsa_amounts({ field, draw, action }: RenderEvent) {
      return (
        <Card background="previous-year-section">
          <Line>
            <Display field={field("@dsEmployeeYear.LSACarriedIn")} />
          </Line>
        </Card>
      );
    }

    previous_year_benefit_details({ field, draw, action }: RenderEvent) {
      let isPlanAdmin = false;
      if (
        this.data.dsPlanAdmins.UserId &&
        this.data.dsPreviousEmployeeBenefits.HSABase === 0 &&
        this.data.dsPreviousEmployeeBenefits.LSABase === 0 &&
        this.data.dsPreviousEmployeeBenefits.FlexBase === 0
      ) {
        isPlanAdmin = true;
      }

      return (
        <Card label="Previous Year Category" background="previous-year-section">
          <Line>
            <Display field={field("@dsPreviousEmployeeBenefits.BenefitName")} />
          </Line>
          <Line>
            <Display
              field={field("@dsPreviousEmployeeBenefits.BenefitCategoryName")}
            />
            {isPlanAdmin ? (
              <></>
            ) : (
              <Display field={field("@dsPreviousEmployeeBenefits.Coverage")} />
            )}
          </Line>
        </Card>
      );
    }

    previous_year_benefit_amounts({ field, draw, action }: RenderEvent) {
      let RSPAmounts = null;

      if (this.data.dsPreviousEmployeeBenefits.HasRSP === true) {
        RSPAmounts = (
          <Line>
              <Display field={field("@dsPreviousEmployeeBenefits.RSPChoiceAmount")} />
          </Line>
        )
      }

      if (this.data.dsPlanAdmins.UserId 
        && this.data.dsPreviousEmployeeBenefits.FlexBase === 0) {
        RSPAmounts = null;
      }

      return (
        RSPAmounts ? (
          <Card label="Previous RSP Year Balance" background="previous-year-section">
            {RSPAmounts}
          </Card>
        ) : null
      );
    }

    previous_hsa_year_benefit_amounts({ field, draw, action }: RenderEvent) {
      let HSAAmounts = null;

      if(this.data.dsPreviousEmployeeBenefits.HasHSA === true)
      {
        if(this.data.dsPolicyBenefit.CarryFwdHSA === "CCF")
        {
          HSAAmounts = (
            <Line>
                <Display field={field("@dsPreviousEmployeeBenefits.HSABalance")} />
                <Display field={field("@dsPreviousEmployeeBenefits.HSAMaxCarryFwd")} />
            </Line>
          )
        }
        else
        {
          HSAAmounts = (
            <Line>
                <Display field={field("@dsPreviousEmployeeBenefits.HSABalance")} />
            </Line>
          )
        }

      }

    
      if(this.data.dsPlanAdmins.UserId 
        && this.data.dsPreviousEmployeeBenefits.HSABase === 0)
      {
        HSAAmounts = null;
      }

      return (
        HSAAmounts? (<Card label="Previous HSA Year Balance" background="previous-year-section">
            {HSAAmounts}
        </Card>) : null
      );
    }

    previous_lsa_year_benefit_amounts({ field, draw, action }: RenderEvent) {

      let LSAAmounts = null;

      if(this.data.dsPreviousEmployeeBenefits.HasLSA === true)
      {
        if(this.data.dsPolicyBenefit.CarryFwdLSA === "CCF")
        {
          LSAAmounts = (
            <Line>
                <Display field={field("@dsPreviousEmployeeBenefits.LSABalance")} />
                <Display field={field("@dsPreviousEmployeeBenefits.LSAMaxCarryFwd")} />
            </Line>
          )
        }
        else
        {
          LSAAmounts = (
            <Line>
                <Display field={field("@dsPreviousEmployeeBenefits.LSABalance")} />
            </Line>
          )
        }
      } 
    
      if(this.data.dsPlanAdmins.UserId  
        && this.data.dsPreviousEmployeeBenefits.LSABase === 0 )
      {
        LSAAmounts = null;

      }

      return (
        LSAAmounts ? (<Card label="Previous LSA Year Balance" background="previous-year-section">
            {LSAAmounts}
        </Card>) : null
      );
    }

    general({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="General"
          actions={
            <>
              <Button action={action(this.edit)} label="Edit" style="link" />
            </>
          }
        >
          <Display field={field("Email")} />
          <Line>
            <Display field={field("MaidenName")} />
            <Display field={field("Nickname")} />
          </Line>
          <Line>
            <Display field={field("EmployeeNo")} />
            <Display field={field("DateOfBirth")} />
          </Line>
        </Card>
      );
    }

    termination_details({ field, draw, action }: RenderEvent) {
      return (
        <Card label="How do Terminations work?">
          <Line>
            <div>
              <b>Benefit End Date</b>: The last day you want the employee to
              have coverage. Only expenses incurred prior to this date will be
              processed.
            </div>
          </Line>
          <Line>
            <div>
              <b>Access</b>: How long the employee can access their account to
              log in, make claims, and see their claim history.
            </div>
          </Line>
          <Line>
            <div>
              <i>
                If you need to temporarily suspend an employee or their
                dependents' coverage, please enter an Inactive Date range under
                the “Date Ranges” tab instead.
              </i>
            </div>
          </Line>
        </Card>
      );
    }

    termination_status({ field, draw, action }: RenderEvent) {
      let terminationDeleteModal = null;
      if (this.state.showTerminationDeleteModal) {
        terminationDeleteModal = (
          <Modal
            className="react-modal react-modal-confirm"
            show={this.state.showTerminationDeleteModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header className="react-modal-title">
              <Modal.Title>Delete termination</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this employee termination?
            </Modal.Body>
            <Modal.Footer>
              <ReactButton
                variant="light"
                onClick={() => this.closeTerminationDeleteModal()}
              >
                Cancel
              </ReactButton>
              <Button
                label="Delete"
                style="destructive"
                action={action(this.remove_termination)}
              ></Button>
            </Modal.Footer>
          </Modal>
        );
      }

      let reinstateModal = null;
      if (this.state.showReinstateModal) {
        reinstateModal = (
          <Modal
            className="react-modal react-modal-confirm"
            show={this.state.showReinstateModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header className="react-modal-title">
              <Modal.Title>Reinstate employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Line>
                  <div>
                    <label>Reinstate Note</label>
                    <input
                      className="RT-TextBox__input"
                      onChange={(e) => this.handleReinstateNoteChange(e)}
                      value={this.state.reinstateNote}
                    />
                  </div>
                </Line>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <ReactButton
                variant="light"
                onClick={() => this.closeReinstateModal()}
              >
                Cancel
              </ReactButton>
              <Button
                label="Reinstate"
                style="primary"
                action={action(this.reinstate_employee)}
              ></Button>
            </Modal.Footer>
          </Modal>
        );
      }

      let terminationLogTable = null;
      if (this.state.terminationLog[0].benefitEndDate != null) {
        terminationLogTable = (
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Date</th>
                  <th>Details</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.terminationLog.map((data, i) => {
                  let terminationNote = null;
                  if (data.terminationNote != null) {
                    terminationNote = <span>, {data.terminationNote}</span>;
                  }
                  let terminationDate = (
                    <ReactMoment format="YYYY-MM-DD">
                      {data.benefitEndDate}
                    </ReactMoment>
                  );
                  let terminationDescription = (
                    <span>
                      Benefit End Date (
                      <ReactMoment format="YYYY-MM-DD">
                        {data.benefitEndDate}
                      </ReactMoment>
                      ),
                      {data.accessAfterTermination} Days Access{terminationNote}
                    </span>
                  );

                  let editTerminationButtons = null;
                  if (data.isActive) {
                    editTerminationButtons = (
                      <div>
                        <Button
                          label="Edit"
                          style="primary"
                          action={action(this.terminate)}
                        ></Button>{" "}
                        <span>or </span>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => this.showTerminationDeleteModal()}
                        >
                          Delete
                        </button>
                      </div>
                    );
                  } else {
                    editTerminationButtons = (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => this.showReinstateModal()}
                      >
                        Reinstate
                      </button>
                    );
                  }

                  return (
                    <tr key={i}>
                      <td>Employee Terminated</td>
                      <td>{terminationDate}</td>
                      <td>{terminationDescription}</td>
                      <td>{editTerminationButtons}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      }

      let btnContainerStyle =
        this.state.terminationLog[0].benefitEndDate != null
          ? { display: "none" }
          : { display: "block" };
      return (
        <>
          {terminationDeleteModal}
          {reinstateModal}
          <Card
            label="Termination"
            actions={
              <div style={btnContainerStyle}>
                <Button
                  action={action(this.terminate)}
                  label="Terminate"
                  style="link"
                />
              </div>
            }
          >
            <Line>
              <CustomDisplayElement
                label="Access After Termination (days)"
                toolTip={
                  <CustomToolTip
                    header="Access After Termination (days)"
                    bodyContent="How long the employee can access their account to log in, make claims, and see their claim history."
                  />
                }
                content={this.data.dsEmployee.AccessAfterTerminationDays}
              />
              <Display
                field={field("BenefitEndDate")}
                toolTip={
                  <CustomToolTip
                    header="Benefit End Date"
                    bodyContent="The last day you want the employee to have coverage. Only expenses incurred prior to this date will be processed."
                  />
                }
              />
            </Line>
            <Line>
              <Display field={field("TerminationNote")} />
            </Line>
            <Line>{terminationLogTable}</Line>
          </Card>
        </>
      );
    }

    limit_adjustment_details({ field, draw, action }: RenderEvent) {
      return (
        <Card label="What is a Limit Adjustment?">
          <Line>
            <div>
              You can temporarily override an employee's benefit limit for their{" "}
              <b>current</b> benefit year to be higher or lower than the limit
              assigned to them by their benefit category. This limit adjustment
              will not carry-forward to the next benefit year after run-off
              ends. Limit adjustments can only be completed during the regular
              benefit year. They can’t be completed when a policy is in Run-Off.
            </div>
          </Line>
          <Line>
            <div>
              <i>
                Example: An employee is in a Manager benefit category and is
                assigned a limit of $1000. They made a $600 claim and now their
                available balance is $400. A limit adjustment of +$250 keeps
                them in the Manager benefit category of $1000, but temporarily
                for the current year changes their limit to $1250 and updates
                their balance to $650. If the account has Credit Carry Forward,
                the amount of credits carried forward to the next year remains
                up to a maximum of $1000.
              </i>
            </div>
          </Line>
        </Card>
      );
    }

    limit_adjustment_previous_year_history({
      field,
      draw,
      action,
    }: RenderEvent) {
      let hsaLimit = 0.0;
      let lsaLimit = 0.0;
      let {
        prevYearHSAAdjustment,
        prevYearLSAAdjustment,
        prevYearHasHsa,
        prevYearHasLsa,
      } = this.state;
      if (prevYearHSAAdjustment != null) {
        hsaLimit = prevYearHSAAdjustment.limit.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      }
      if (prevYearLSAAdjustment != null) {
        lsaLimit = prevYearLSAAdjustment.limit.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      }

      let hsaLogTable = null;
      if (
        prevYearHSAAdjustment != null &&
        prevYearHSAAdjustment.employeePreviousYearLedger != null
      ) {
        if (prevYearHSAAdjustment.employeePreviousYearLedger.length > 0) {
          let rows = prevYearHSAAdjustment.employeePreviousYearLedger.map(
            (data, i) => {
              return (
                <tr key={i}>
                  <td className="adj-date-col">{data.transDate}</td>
                  <td className="adj-credit-col">
                    {data.credit.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </td>
                  <td className="adj-reason-col">{data.reason}</td>
                </tr>
              );
            }
          );

          hsaLogTable = (
            <table className="table">
              <thead>
                <tr>
                  <th className="adj-date-col">Adjustment Date</th>
                  <th className="adj-credit-col">Credit</th>
                  <th className="adj-reason-col">Reason</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          );
        }
      }

      let lsaLogTable = null;
      if (
        prevYearLSAAdjustment != null &&
        prevYearLSAAdjustment.employeePreviousYearLedger != null
      ) {
        if (prevYearLSAAdjustment.employeePreviousYearLedger.length > 0) {
          let rows = prevYearLSAAdjustment.employeePreviousYearLedger.map(
            (data, i) => {
              return (
                <tr key={i}>
                  <td className="adj-date-col">{data.transDate}</td>
                  <td className="adj-credit-col">
                    {data.credit.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </td>
                  <td className="adj-reason-col">{data.reason}</td>
                </tr>
              );
            }
          );

          lsaLogTable = (
            <table className="table">
              <thead>
                <tr>
                  <th className="adj-date-col">Adjustment Date</th>
                  <th className="adj-credit-col">Credit</th>
                  <th className="adj-reason-col">Reason</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          );
        }
      }

      let hsaCard = null;
      if (prevYearHasHsa) {
        hsaCard = (
          <div className="RT-Section RT-Section--background-previous-year-section">
            <div className="RT-Section__header">
              <div className="RT-Section__title">Previous Year HSA Limit</div>
            </div>

            <div className="RT-Line" style={{ paddingBottom: 25 }}>
              <div className="RT-Line__content RT-Line__content--space-evenly">
                <div className="RT-Line__item">
                  <div className="RT-Static-Field">
                    <label className="RT-Static-Field__label">HSA Limit</label>
                    <div className="RT-Static-Field__content">{hsaLimit}</div>
                  </div>
                </div>
                <div className="RT-Line__item">
                  <div className="RT-Static-Field">
                    <label className="RT-Static-Field__label">
                      Has HSA Limit Adjustment
                    </label>
                    <div className="RT-Static-Field__content">
                      {prevYearHSAAdjustment.hasAdjustment === true ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {this.renderStaticCheckmark()}
                          <div>Yes</div>
                        </div>
                      ) : (
                        <span>No</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {hsaLogTable}
          </div>
        );
      }

      let lsaCard = null;
      if (prevYearHasLsa) {
        lsaCard = (
          <div className="RT-Section RT-Section--background-previous-year-section">
            <div className="RT-Section__header">
              <div className="RT-Section__title">Previous Year LSA Limit</div>
            </div>

            <div className="RT-Line">
              <div className="RT-Line__content RT-Line__content--space-evenly">
                <div className="RT-Line__item">
                  <div className="RT-Static-Field">
                    <label className="RT-Static-Field__label">LSA Limit</label>
                    <div className="RT-Static-Field__content">{lsaLimit}</div>
                  </div>
                </div>

                <div className="RT-Line__item">
                  <div className="RT-Static-Field">
                    <label className="RT-Static-Field__label">
                      Has LSA Limit Adjustment
                    </label>
                    <div className="RT-Static-Field__content">
                      {prevYearLSAAdjustment.hasAdjustment === true ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {this.renderStaticCheckmark()}
                          <div>Yes</div>
                        </div>
                      ) : (
                        <span>No</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {lsaLogTable}
          </div>
        );
      }

      return (
        <>
          {hsaCard}
          {lsaCard}
        </>
      );
    }

    limit_adjustment_history({ field, draw, action }: RenderEvent) {
      let adjustmentModal = null;
      let hsaLimit = 0.0;
      let lsaLimit = 0.0;

      if (this.state.hsaAdjustment != null) {
        hsaLimit = this.state.hsaAdjustment.limit.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      }
      if (this.state.lsaAdjustment != null) {
        lsaLimit = this.state.lsaAdjustment.limit.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      }

      let hsaLogTable = null;
      if (
        this.state.hsaAdjustment != null &&
        this.state.hsaAdjustment.employeeCurrentYearLedger != null
      ) {
        if (this.state.hsaAdjustment.employeeCurrentYearLedger.length > 0) {
          let rows = this.state.hsaAdjustment.employeeCurrentYearLedger.map(
            (data, i) => {
              return (
                <tr key={i}>
                  <td className="adj-date-col">{data.transDate}</td>
                  <td className="adj-credit-col">
                    {data.credit.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </td>
                  <td className="adj-reason-col">{data.reason}</td>
                </tr>
              );
            }
          );

          hsaLogTable = (
            <table className="table">
              <thead>
                <tr>
                  <th className="adj-date-col">Adjustment Date</th>
                  <th className="adj-credit-col">Credit</th>
                  <th className="adj-reason-col">Reason</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          );
        }
      }

      let lsaLogTable = null;
      if (
        this.state.lsaAdjustment != null &&
        this.state.lsaAdjustment.employeeCurrentYearLedger != null
      ) {
        if (this.state.lsaAdjustment.employeeCurrentYearLedger.length > 0) {
          let rows = this.state.lsaAdjustment.employeeCurrentYearLedger.map(
            (data, i) => {
              return (
                <tr key={i}>
                  <td className="adj-date-col">{data.transDate}</td>
                  <td className="adj-credit-col">
                    {data.credit.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </td>
                  <td className="adj-reason-col">{data.reason}</td>
                </tr>
              );
            }
          );

          lsaLogTable = (
            <table className="table">
              <thead>
                <tr>
                  <th className="adj-date-col">Adjustment Date</th>
                  <th className="adj-credit-col">Credit</th>
                  <th className="adj-reason-col">Reason</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          );
        }
      }

      let hsaCard = null;
      if (this.state.hasHsa) {
        hsaCard = (
          <div className="RT-Section">
            <div className="RT-Section__header">
              <div className="RT-Section__title">HSA Limit</div>
            </div>
            <div className="RT-Line" style={{ paddingBottom: 25 }}>
              <div className="RT-Line__content RT-Line__content--space-evenly">
                <div className="RT-Line__item">
                  <div className="RT-Static-Field">
                    <label className="RT-Static-Field__label">HSA Limit </label>
                    <div className="RT-Static-Field__content">{hsaLimit}</div>
                  </div>
                </div>
                <div className="RT-Line__item">
                  <div className="RT-Static-Field">
                    <label className="RT-Static-Field__label">
                      Has HSA Limit Adjustment
                    </label>
                    <div className="RT-Static-Field__content">
                      {this.state.hsaAdjustment.hasAdjustment ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {this.renderStaticCheckmark()}
                          <div>Yes</div>
                        </div>
                      ) : (
                        <span>No</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {hsaLogTable}
          </div>
        );
      }
      let lsaCard = null;
      if (this.state.hasLsa) {
        lsaCard = (
          <div className="RT-Section">
            <div className="RT-Section__header">
              <div className="RT-Section__title">LSA Limit</div>
            </div>
            <div className="RT-Line">
              <div className="RT-Line__content RT-Line__content--space-evenly">
                <div className="RT-Line__item">
                  <div className="RT-Static-Field">
                    <label className="RT-Static-Field__label">LSA Limit </label>
                    <div className="RT-Static-Field__content">{lsaLimit}</div>
                  </div>
                </div>

                <div className="RT-Line__item">
                  <div className="RT-Static-Field">
                    <label className="RT-Static-Field__label">
                      Has LSA Limit Adjustment
                    </label>
                    <div className="RT-Static-Field__content">
                      {this.state.lsaAdjustment.hasAdjustment ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {this.renderStaticCheckmark()}
                          <div>Yes</div>
                        </div>
                      ) : (
                        <span>No</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {lsaLogTable}
          </div>
        );
      }

      return (
        <>
          {hsaCard}
          {lsaCard}
        </>
      );
    }

    bonus_credit_history({ field, draw, action }: RenderEvent) {
      const {
        hsaBonusCredit,
        lsaBonusCredit,
        hasHsa,
        hasLsa,
        showBonusCreditModal,
        selectedBonusCreditType,
        currentBonusCredit,
        inputBonusCredit,
        inputBonusCreditNote,
      } = this.state;

      const formatCurrency = (amount) =>
        amount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

      const renderLogTable = (ledger) =>
        ledger.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th className="adj-date-col">Date</th>
                <th className="adj-credit-col">Credit</th>
                <th className="adj-reason-col">Reason</th>
                <th className="adj-createdby-col">Created By</th>
              </tr>
            </thead>
            <tbody>
              {ledger.map((data, i) => (
                <tr key={i}>
                  <td className="adj-date-col">{data.transDate}</td>
                  <td className="adj-credit-col">
                    {formatCurrency(data.credit)}
                  </td>
                  <td className="adj-reason-col">{data.reason}</td>
                  <td className="adj-createdby-col">{data.createdBy}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null;

      const renderCreditCard = (title, credit, ledger, type) => (
        <div className="RT-Section">
          <div className="RT-Section__header">
            <div className="RT-Section__title">{title} Bonus Credit</div>
            <div className="RT-Section__actions">
              <a
                href="#"
                className="RT-Link"
                onClick={() => this.openBonusCreditModal(type, credit)}
              >
                Add
              </a>
            </div>
          </div>
          <div className="RT-Line" style={{ paddingBottom: 25 }}>
            <div className="RT-Line__content RT-Line__content--space-evenly">
              <div className="RT-Line__item">
                <div className="RT-Static-Field">
                  <label className="RT-Static-Field__label">
                    {title} Credit
                  </label>
                  <div className="RT-Static-Field__content">{credit}</div>
                </div>
              </div>
            </div>
          </div>
          {renderLogTable(ledger)}
        </div>
      );

      const renderBonusCreditModal = () => (
        <Modal
          className="react-modal"
          show={showBonusCreditModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="react-modal-title">
            <Modal.Title>Add {selectedBonusCreditType} Credit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="RT-Section">
              <div className="RT-Line">
                <div className="RT-Line__content RT-Line__content--space-evenly">
                  <div className="RT-Line__item">
                    <div className="RT-TextBox">
                      <label>Employee</label>
                      <div className="RT-TextBox__container">
                        {this.data.dsEmployee.FirstName}{" "}
                        {this.data.dsEmployee.LastName}
                      </div>
                    </div>
                  </div>
                  <div className="RT-Line__item">
                    <div className="RT-TextBox">
                      <label>Product</label>
                      <div className="RT-TextBox__container">
                        Spending Account
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="RT-Section">
              <div className="RT-Line">
                <div className="RT-Line__content RT-Line__content--space-evenly">
                  <div className="RT-Line__item">
                    <div className="RT-TextBox">
                      <label>Current {selectedBonusCreditType} Credit</label>
                      <div className="RT-TextBox__container">
                        {currentBonusCredit}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="RT-Line__content RT-Line__content--space-evenly">
                  <div className="RT-Line__item">
                    <div className="RT-TextBox" style={{ marginTop: 10 }}>
                      Enter the dollar amount of credit to be applied to the
                      limit. This will be added to any previous credits,
                      <strong>
                        {" "}
                        and will also carry forward if Credit Carry Forward
                        applies at the policy level.
                      </strong>
                    </div>
                  </div>
                </div>

                <div className="RT-Line__content RT-Line__content--space-evenly">
                  <div className="RT-Line__item" style={{ maxWidth: "50%" }}>
                    <div className="RT-TextBox">
                      <label>{selectedBonusCreditType} Credit</label>
                      <div className="RT-TextBox__container input-group">
                        <span className="input-group-text">$</span>
                        <input
                          type="number"
                          className="form-control"
                          id="limit"
                          value={inputBonusCredit}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (newValue === "" || /^[0-9]*$/.test(newValue)) {
                              this.setState({
                                inputBonusCredit: e.target.value,
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="RT-Line__content RT-Line__content--space-evenly">
                  <div className="RT-Line__item">
                    <div className="RT-TextBox">
                      <label>Reason</label>
                      <div className="RT-TextBox__container">
                        <textarea
                          onChange={(e) =>
                            this.setState({
                              inputBonusCreditNote: e.target.value,
                            })
                          }
                          rows={4}
                          className="RT-TextBox__input"
                          value={inputBonusCreditNote}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="light"
              onClick={() => this.closeBonusCreditModal()}
            >
              Cancel
            </ReactButton>
            <Button
              label="Save"
              style="primary"
              disable={inputBonusCredit < 0 || inputBonusCredit >= 20000}
              action={action(this.submitBonusCredit)}
            />
          </Modal.Footer>
        </Modal>
      );

      return (
        <>
          {showBonusCreditModal && renderBonusCreditModal()}
          {hasHsa &&
            hsaBonusCredit != null &&
            renderCreditCard(
              "HSA",
              formatCurrency(hsaBonusCredit.bonusCredit),
              hsaBonusCredit.employeeYearLedger,
              "HSA"
            )}
          {hasLsa &&
            lsaBonusCredit != null &&
            renderCreditCard(
              "LSA",
              formatCurrency(lsaBonusCredit.bonusCredit),
              lsaBonusCredit.employeeYearLedger,
              "LSA"
            )}
        </>
      );
    }

    bonus_credit_previous_year_history({ field, draw, action }: RenderEvent) {
      const { prevYearHsaBonusCredit, prevYearLsaBonusCredit, hasHsa, hasLsa } =
        this.state;

      const formatCurrency = (amount) =>
        amount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

      const renderLogTable = (ledger) =>
        ledger.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th className="adj-date-col">Date</th>
                <th className="adj-credit-col">Credit</th>
                <th className="adj-reason-col">Reason</th>
                <th className="adj-createdby-col">Created By</th>
              </tr>
            </thead>
            <tbody>
              {ledger.map((data, i) => (
                <tr key={i}>
                  <td className="adj-date-col">{data.transDate}</td>
                  <td className="adj-credit-col">
                    {formatCurrency(data.credit)}
                  </td>
                  <td className="adj-reason-col">{data.reason}</td>
                  <td className="adj-createdby-col">{data.createdBy}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null;

      const renderCreditCard = (title, credit, ledger, type) => (
        <div className="RT-Section RT-Section--background-previous-year-section">
          <div className="RT-Section__header">
            <div className="RT-Section__title">{title} Bonus Credit</div>
          </div>
          <div className="RT-Line" style={{ paddingBottom: 25 }}>
            <div className="RT-Line__content RT-Line__content--space-evenly">
              <div className="RT-Line__item">
                <div className="RT-Static-Field">
                  <label className="RT-Static-Field__label">
                    {title} Credit
                  </label>
                  <div className="RT-Static-Field__content">{credit}</div>
                </div>
              </div>
            </div>
          </div>
          {renderLogTable(ledger)}
        </div>
      );

      return (
        <>
          {hasHsa &&
            prevYearHsaBonusCredit != null &&
            renderCreditCard(
              "Previous Year HSA",
              formatCurrency(prevYearHsaBonusCredit.bonusCredit),
              prevYearHsaBonusCredit.employeeYearLedger,
              "HSA"
            )}
          {hasLsa &&
            prevYearLsaBonusCredit != null &&
            renderCreditCard(
              "Previous Year LSA",
              formatCurrency(prevYearLsaBonusCredit.bonusCredit),
              prevYearLsaBonusCredit.employeeYearLedger,
              "LSA"
            )}
        </>
      );
    }

    reporting({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Reporting"
          actions={
            <>
              <Button action={action(this.edit)} label="Edit" style="link" />
            </>
          }
        >
          <Line>
            <Display field={field("@dsDivision.FullName")} />
            <Display field={field("@dsCostCentre.FullName")} />
          </Line>
        </Card>
      );
    }

    benefit_start({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Benefits"
          actions={
            <>
              <Button
                action={action(this.edit_bencat)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display
              field={field("@dsBenefitCategory.Name")}
              textStyle="bold"
            />
            <Display
              field={field("WaitingPeriodMonths")}
              toolTip={
                <CustomToolTip
                  header="Waiting Period (months)"
                  bodyContent="Choose how long an employee waits after being hired for their benefits to start."
                />
              }
            />
            <Display
              field={field("BenefitStartDate")}
              toolTip={
                <CustomToolTip
                  header="Benefit Start Date"
                  bodyContent="Based on pro-rating and waiting period, the system calculates the employee’s Benefit Start Date. The system suggested date can be overridden by PAs."
                />
              }
            />
          </Line>
        </Card>
      );
    }

    benefit_dates({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Benefit Dates Set Up"
          actions={
            <>
              <Button
                action={action(this.edit_bencat)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display field={field("BenefitStartDate")} />
            <Display field={field("WaitingPeriodMonths")} />
            <Display field={field("@dsEmployeeYear.HasProrating")} />
          </Line>
        </Card>
      );
    }

    contact_info({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Contact Information"
          actions={
            <>
              <Button
                action={action(this.edit_contact)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Display field={field("MailingStreet1")} />
          <Display field={field("MailingStreet2")} />
          <Line>
            <Display field={field("MailingCity")} />
            <Display field={field("MailingProv")} />
          </Line>
          <Line>
            <Display field={field("MailingCountry")} />
            <Display field={field("MailingPostalCode")} />
          </Line>
          <Line>
            <Display field={field("HomePhone")} />
            <Display field={field("MobilePhone")} />
          </Line>
        </Card>
      );
    }

    custom_category({ field, draw, action }: RenderEvent) {
      if (this.state.employeeCustomCategories == null) return;

      let claimCarryDesc = (
        <label style={{ paddingBottom: 10 }}>
          This claim total was carried from a previous Enhanced Benefit of the
          same Title and Description.
        </label>
      );

      return (
        <div className="RT-Section">
          <div className="RT-Section__header">
            <div className="RT-Section__title">Enhanced Benefits</div>
          </div>
          {this.state.employeeCustomCategories.map((category, index) => (
            <React.Fragment key={index}>
              <div className="RT-Line">
                <div className="RT-Line__content RT-Line__content--space-evenly">
                  <div className="RT-Line__item">
                    <div className="RT-Static-Field">
                      <div
                        className="RT-Static-Field__label"
                        style={{ marginBottom: 0 }}
                      >
                        Category Name
                      </div>
                      <div className="RT-Static-Field__content">
                        {category.customCategoryName}
                      </div>
                    </div>
                  </div>
                  <div className="RT-Line__item">
                    <div className="RT-Static-Field">
                      <div
                        className="RT-Static-Field__label"
                        style={{ marginBottom: 0 }}
                      >
                        Category Type
                      </div>
                      <div className="RT-Static-Field__content">
                        {category.customCategoryType}
                      </div>
                    </div>
                  </div>
                  <div className="RT-Line__item">
                    <div className="RT-Static-Field">
                      <div
                        className="RT-Static-Field__label"
                        style={{ marginBottom: 0 }}
                      >
                        Limit
                      </div>
                      <div className="RT-Static-Field__content">
                        ${category.customCategoryLimit}
                      </div>
                    </div>
                  </div>
                  <div className="RT-Line__item">
                    <div className="RT-Static-Field">
                      <div
                        className="RT-Static-Field__label"
                        style={{ marginBottom: 0 }}
                      >
                        Balance
                      </div>
                      <div className="RT-Static-Field__content">
                        ${category.customCategoryBalance}
                      </div>
                    </div>
                  </div>
                  {!category.isActive && <div className="RT-Line__item">
                    <div className="RT-Static-Field">
                      <div
                        className="RT-Static-Field__label"
                        style={{ marginBottom: 0 }}
                      >
                        Eligibility
                        <CustomToolTip
                          header="Eligibility"
                          bodyContent="If “Ineligible”, the Enhanced Benefit has been disabled and the employee does not have access to its remaining balances."
                        />
                      </div>
                      <div className="RT-Static-Field__content">
                        Ineligible
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
              {category.hasPreviousSubCat && claimCarryDesc}
              {category.updatedBy != null && (
                <div>
                  <label>
                    This enhanced benefit has been updated by{" "}
                    {category.updatedBy} at{" "}
                    <Moment format="YYYY-MM-DD hh:mm A">
                      {category.updatedAt}
                    </Moment>
                  </label>
                </div>
              )}
            </React.Fragment>
          ))}
          {this.renderCustomCategoryModal()}
          {this.renderAcknowledgmentModal()}
        </div>
      );
    }

    previous_year_custom_category({ field, draw, action }: RenderEvent) {
      if (this.state.employeePrevCustomCategories == null) return;

      return (
        <Card
          label="Previous Year Enhanced Benefit"
          background="previous-year-section"
        >
          {this.state.employeePrevCustomCategories.map((category, index) => (
            <Line key={index}>
              <div className="RT-Static-Field">
                <div
                  className="RT-Static-Field__label"
                  style={{ marginBottom: 0 }}
                >
                  Category Name
                </div>
                <div className="RT-Static-Field__content">
                  {category.customCategoryName}
                </div>
              </div>
              <div className="RT-Static-Field">
                <div
                  className="RT-Static-Field__label"
                  style={{ marginBottom: 0 }}
                >
                  Category Type
                </div>
                <div className="RT-Static-Field__content">
                  {category.customCategoryType}
                </div>
              </div>
              <div className="RT-Static-Field">
                <div
                  className="RT-Static-Field__label"
                  style={{ marginBottom: 0 }}
                >
                  Limit
                </div>
                <div className="RT-Static-Field__content">
                  ${category.customCategoryLimit}
                </div>
              </div>
              <div className="RT-Static-Field">
                <div
                  className="RT-Static-Field__label"
                  style={{ marginBottom: 0 }}
                >
                  Balance
                </div>
                <div className="RT-Static-Field__content">
                  ${category.customCategoryBalance}
                </div>
              </div>
              <div className="RT-Static-Field">
                <div
                  className="RT-Static-Field__label"
                  style={{ marginBottom: 0 }}
                >
                  Eligibility
                  <CustomToolTip
                    header="Eligibility"
                    bodyContent="If “Ineligible”, the Enhanced Benefit has been disabled and the employee does not have access to its remaining balances."
                  />
                </div>
                <div className="RT-Static-Field__content">
                  {category.isActive ? "Eligible" : "Ineligible"}
                </div>
              </div>
            </Line>
          ))}
        </Card>
      );
    }

    renderCustomCategoryModal() {
      if (!this.state.editableCategories) {
        return null;
      }

      return (
        <Modal
          show={this.state.showCustomCategoryModal}
          centered
          style={{ top: 200 }}
        >
          <Modal.Header>
            <Modal.Title style={{ fontSize: 17, fontWeight: 500 }}>
              Edit Eligibility
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ minHeight: 200, maxHeight: 400, overflowY: "auto" }}
          >
            {this.state.editableCategories.map((category, index) => (
              <div
                className="RT-Section edit-eligibility"
                style={{ maxHeight: 100 }}
              >
                <Line>
                  <div className="RT-Static-Field" style={{ height: 70 }}>
                    <label className="RT-Static-Field__label">
                      Category Name
                    </label>
                    <div
                      className="RT-Static-Field__content"
                      style={{ paddingTop: 20 }}
                    >
                      {category.customCategoryName}
                    </div>
                  </div>
                  <div style={{ width: "250px" }}>
                    <div className="RT-TextBox edit-employee-ben-cat">
                      <label className="RT-Label">
                        Eligiblity
                        <CustomToolTip
                          header="Edit Eligibility"
                          bodyContent="Marking an Enhanced Benefit as “Ineligible” immediately removes the employee’s access to the Account as well as any remaining balance. The Account and amounts already claimed will remain visible in Ops and in PA View, as well as in reporting, but will only appear at the employee level under the limit breakdown. It will no longer appear at adjudication. Selecting “Eligible” will immediately restore the employee’s access to the Account and its remaining balance."
                        />
                      </label>
                      <CustomDropdown
                        options={["Eligible", "Ineligible"]}
                        selectedOption={category.status}
                        setSelectedOption={(val) => {
                          this.handleCategoryChange(index, val);
                        }}
                      />
                    </div>
                  </div>
                </Line>
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.hideCustomCategoryModal}
            >
              Cancel
            </ReactButton>
            <ReactButton
              variant="primary"
              onClick={this.showAcknowledgmentModal}
            >
              Save
            </ReactButton>
          </Modal.Footer>
        </Modal>
      );
    }

    renderAcknowledgmentModal() {
      return (
        <Modal
          show={this.state.showAcknowledgmentModal}
          style={{ top: 200 }}
          centered
        >
          <Modal.Header>
            <Modal.Title style={{ fontSize: 17, fontWeight: 500 }}>
              Acknowledgment
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ maxHeight: 400, overflowY: "auto", color: "red" }}
          >
            <Card>
              <p>
                By clicking “Confirm” to enable or disable an Enhanced Benefit,
                you are acknowledging that the change is effective immediately.
                <br />
                <ul
                  style={{ listStyle: "circle", padding: 15, marginLeft: 10 }}
                >
                  <li style={{ marginBottom: 10 }}>
                    If “Ineligible”, the employee will no longer have access to
                    the remaining balance. Any claims still pending processing,
                    that have not yet been finalized, will no longer be eligible
                    under this Enhanced Benefit. Previous claims paid from any
                    Enhanced Benefits are irreversible, and data from disabled
                    Enhanced Benefits will remain reflected in reporting.
                  </li>
                  <li>
                    If “Eligible”, the employee will immediately regain
                    eligibility and access to their balance.
                  </li>
                </ul>
              </p>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.hideAcknowledgmentModal}
            >
              Cancel
            </ReactButton>
            <ReactButton
              variant="primary"
              onClick={this.saveCustomCategoryEligibility}
            >
              Confirm
            </ReactButton>
          </Modal.Footer>
        </Modal>
      );
    }

    async edit({ screen }: ActionEvent) {
      await screen.openDialog(
        <EditScreen employee_id={this.data.dsEmployee.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async refresh_data({ screen }: ActionEvent) {
      await screen.restart();
    }

    async edit_contact({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_addressScreen employee_id={this.data.dsEmployee.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_bencat({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_bencatScreen employee_id={this.data.dsEmployee.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_benefitDates({ screen }: ActionEvent) {
      await screen.openDialog(
        <BenefitEditBenefitDatesScreen
          employeeBenefit_id={this.data.dsEmployeeYear.Id}
        />,
        { onRecordChanged: this.refresh_data }
      );

      await this.checkEmployeeDateRange();
    }

    async edit_benefits({ screen }: ActionEvent) {
      await screen.openDialog(
        <BenefitEditScreen employeeBenefit_id={this.data.dsEmployeeYear.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_flex_dates({ screen }: ActionEvent) {
      await screen.openDialog(
        <BenefitEditFlexDatesScreen
          employeeBenefit_id={this.data.dsEmployeeYear.Id}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_startDate({ screen }: ActionEvent) {
      await screen.openDialog(
        <BenefitEditEmployeeStartDateScreen
          employeeBenefit_id={this.data.dsEmployeeYear.Id}
        />,
        { onRecordChanged: this.refresh_data }
      );

      await this.checkEmployeeDateRange();
    }

    renderStaticCheckmark() {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          style={{
            display: "block",
            width: 20,
            height: 20,
            fill: "var(--rt-primary-color)",
          }}
        >
          <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z" />
        </svg>
      );
    }

    async edit_bendates({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_bencatScreen employee_id={this.data.dsEmployee.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async terminate({ screen }: ActionEvent) {
      await screen.openDialog(
        <TerminateScreen
          employee_id={this.data.dsEmployee.Id}
          access_days={this.state.terminationLog[0].accessAfterTermination}
        />,
        { onRecordChanged: this.refresh_data }
      );
      this.getTerminationLog();
      await screen.restart();
    }

    async remove_termination({ screen }: ActionEvent) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          EmployeeId: this.data.dsEmployee.Id,
        }),
      };
      await fetch("/api/Terminate", requestOptions).then(
        (data) => {
          if (data.ok)
            fetch("/api/PlanAdmin/removeterminationlogs", requestOptions);
        }
      );
      this.closeTerminationDeleteModal();
      this.getTerminationLog();
      await screen.restart();
    }

    async reinstate_employee({ screen }: ActionEvent) {
      let now = new Date();
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          employeeId: this.data.dsEmployee.Id,
          reinstateDate: now.toISOString(),
          reinstateNote: this.state.reinstateNote,
        }),
      };
      const employeeLogs = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          employeeId: this.data.dsEmployee.Id,
          reinstateDate: now.toISOString(),
          reinstateNote: this.state.reinstateNote,
        }),
      };

      await fetch("/api/Employees/reinstateemployee", requestOptions).then(
        (data) => {
          if (data.ok)
            fetch("/api/PlanAdmin/reinstatelogs", employeeLogs);
        }
      );
      this.closeReinstateModal();
      this.getTerminationLog();
      await screen.restart();
    }

    async submitBonusCredit({ screen }: ActionEvent) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          employeeId: this.data.dsEmployee.Id,
          accountType: this.state.selectedBonusCreditType,
          credit: this.state.inputBonusCredit,
          note: this.state.inputBonusCreditNote,
        }),
      };

      await fetch("/api/employees/setbonuscredit", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            toastr.error(data.message);
          }
        });

      this.closeBonusCreditModal();
      this.getBonusCredit();
      this.setState({ inputBonusCredit: 0, inputBonusCreditNote: "" });
      await screen.restart();
    }

    checkFirstYearCoverage = () => {
      var self = this;
      axios
        .get(
          "/api/PlanAdmin/checkfirstyearcoverage?employeeId=" +
            this.data.dsEmployee.Id
        )
        .then((response) => {
          self.setState({
            enableBenefitDateEdit: response.data.enableEdit,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    getEmployeeCHInfo = () => {
      var self = this;
      axios
        .get(
          "/api/Reports/getEmployeeCHInfo?currentBenefitYearId=" +
            this.data.dsPolicies.CurrentBenYear_Id +
            "&employeeId=" +
            this.data.dsEmployee.Id
        )
        .then((response) => {
          self.setState({
            chInfo: response.data,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    checkIsPolicyActive() {
      var self = this;
      axios
        .get(
          "/api/Employees/checkispolicyactive?policyId=" +
            this.data.dsBenefitCategory.Policy_Id
        )
        .then((response) => {
          self.setState({
            isPolicyActive: response.data.isActive,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    checkIsAllowBenefitCategoryEdit = () => {
      var self = this;
      axios
        .get(
          "/api/PlanAdmin/isallowbenefitcategoryedit?policyId=" +
            this.data.dsBenefitCategory.Policy_Id
        )
        .then((response) => {
          self.setState({
            isAllowBenefitCategoryEdit:
              response.data.isAllowBenefitCategoryEdit,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    computeProratedMonths(
      StartDate: string,
      EndDate: string,
      BenefitEffectiveDate: string,
      HireDate: string,
      WaitingPeriodMonths: number
    ): any {
      let endDate = moment(EndDate, "YYYY-MM-DD");
      let startDate = moment(StartDate, "YYYY-MM-DD");
      let benefitEffectiveDate = moment(BenefitEffectiveDate, "YYYY-MM-DD");
      let hireDate = moment(HireDate, "YYYY-MM-DD");

      let benefitStartDate = this.computeBenefitStartDate(
        benefitEffectiveDate,
        hireDate,
        WaitingPeriodMonths
      );
      let policyYearEndMonth = this.computeSerialMonth(
        endDate.year(),
        endDate.month()
      );
      let policyYearStartMonth;
      if (
        benefitStartDate.isBefore(benefitEffectiveDate) ||
        benefitStartDate.isSame(benefitEffectiveDate)
      ) {
        policyYearStartMonth = this.computeSerialMonth(
          benefitEffectiveDate.year(),
          benefitEffectiveDate.month()
        );
      } else {
        policyYearStartMonth = this.computeSerialMonth(
          benefitStartDate.year(),
          benefitStartDate.month()
        );
      }
      let proratedMonths = policyYearEndMonth - policyYearStartMonth + 1;

      return proratedMonths;
    }

    computeBenefitStartDate(
      benefitEffectiveDate: any,
      hireDate: any,
      waitingPeriodMonths: number
    ): any {
      if (hireDate == null) {
        return benefitEffectiveDate;
      }
      var benefitStartDate = hireDate.add(waitingPeriodMonths, "months");
      if (
        benefitStartDate.isBefore(benefitEffectiveDate) ||
        benefitStartDate.isSame(benefitEffectiveDate)
      ) {
        return benefitEffectiveDate;
      }
      return benefitStartDate;
    }

    computeSerialMonth(year: any, month: any): any {
      return year * 12 + month + 1;
    }

    checkEmployeeDateRange = async () => {
      var self = this;
      await axios
        .get(
          "/api/Employees/checkemployeedaterange?employeeId=" +
            this.props.employee_id
        )
        .then((response) => {
          self.setState({
            showDateRangeModal: response.data.showDateRangeModal,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    checkEmployeeStartDate = async () => {
      var self = this;
      await axios
        .get(
          "/api/Employees/checkssstartdatevalid?employeeId=" +
            this.props.employee_id
        )
        .then((response) => {
          self.setState({
            isStartDateValid: response.data.isStartDateValid,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    getTerminationLog = () => {
      fetch("/api/Terminate?id=" + this.props.employee_id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            terminationLog: data,
          });
        })
        .catch((error) => {
          toastr.error("Failed to get termination log", "Error");
        });
    };

    getAdjustments = () => {
      fetch(
        "/api/employees/getadjustingentry?employeeId=" + this.props.employee_id,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            hasHsa: data.hasHsa,
            hasLsa: data.hasLsa,
            hsaAdjustment: data.hsaAdjustment,
            lsaAdjustment: data.lsaAdjustment,
          });
        })
        .catch((error) => {
          toastr.error("Failed to get adjustments", "Error");
        });
    };

    getBonusCredit = () => {
      fetch(
        "/api/employees/getbonuscredit?employeeId=" + this.props.employee_id,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            hsaBonusCredit: data.hsaBonusCredit,
            lsaBonusCredit: data.lsaBonusCredit,
          });
        })
        .catch((error) => {
          toastr.error("Failed to get bonus credit", "Error");
        });
    };

    getPreviousYearBonusCredit = () => {
      fetch(
        "/api/employees/getpreviousyearbonuscredit?employeeId=" +
          this.props.employee_id,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            prevYearHsaBonusCredit: data.hsaPreviousYearBonusCredit,
            prevYearLsaBonusCredit: data.lsaPreviousYearBonusCredit,
          });
        })
        .catch((error) => {
          toastr.error("Failed to previous year bonus credit", "Error");
        });
    };

    getPreviousYearAdjustments = () => {
      fetch(
        "/api/employees/getpreviousyearadjustingentry?employeeId=" +
          this.props.employee_id,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            prevYearHasHsa: data.prevYearHasHsa,
            prevYearHasLsa: data.prevYearHasLsa,
            prevYearHSAAdjustment: data.prevYearHSAAdjustment,
            prevYearLSAAdjustment: data.prevYearLSAAdjustment,
          });
        })
        .catch((error) => {
          toastr.error("Failed to get previous year adjustments", "Error");
        });
    };

    getEmployeeCustomCategories = () => {
      fetch(
        "/api/employees/getemployeecustomcategories?employeeId=" +
          this.props.employee_id,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            employeeCustomCategories: data.groupedCategories,
            editableCategories: data.groupedCategories.map((cat) => ({
              id: cat.id,
              customCategoryName: cat.customCategoryName,
              status: cat.isActive ? "Eligible" : "Ineligible",
            })),
          });
        })
        .catch((error) => {
          toastr.error("Failed to get employee custom categories", "Error");
        });
    };

    getEmployeePrevCustomCategories = () => {
      fetch(
        "/api/employees/getemployeeprevcustomcategories?employeeId=" +
          this.props.employee_id,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            employeePrevCustomCategories: data.employeePrevCustomCategories,
          });
        })
        .catch((error) => {
          toastr.error(
            "Failed to get Employee Previous Custom Categor Information",
            "Error"
          );
        });
    };

    showTerminationDeleteModal = () => {
      this.setState({ showTerminationDeleteModal: true });
    };

    closeTerminationDeleteModal = () => {
      this.setState({ showTerminationDeleteModal: false });
    };

    openBonusCreditModal = (type, credit) => {
      this.setState({
        showBonusCreditModal: true,
        selectedBonusCreditType: type,
        currentBonusCredit: credit,
      });
    };

    closeBonusCreditModal = () => {
      this.setState({ showBonusCreditModal: false });
    };

    resendFlexEmail = () => {
      const { dsEmployee } = this.data;
      const { dsEmployeeYear } = this.data;
      const { dsBenefitCategory } = this.data;

      axios
        .post("/api/Employees/resendflexselectionemail", {
          benefitCategoryId: dsBenefitCategory.Id,
          policyId: dsBenefitCategory.Policy_Id,
          benefitYearId: dsEmployeeYear.BenefitYear_Id,
          flexSelectionDeadline: dsEmployeeYear.FlexSelectionDeadline,
          firstName: dsEmployee.FirstName,
          lastName: dsEmployee.LastName,
          email: dsEmployee.Email,
          employeeId: dsEmployee.Id,
          employeeYearId: dsEmployeeYear.Id,
          limit: dsEmployeeYear.FlexBase,
        })
        .then((response) => {
          if (response.data.success) {
            toastr.success(response.data.message);
          } else {
            toastr.error("Failed to send email");
          }
        })
        .catch((error) => {
          toastr.error("Failed to send email");
        });
    };

    resendEmployeeWelcomeEmail = () => {
      const { dsEmployee, dsEmployeeYear, dsBenefitCategory, dsPolicyHolder } =
        this.data;

      axios
        .post("/api/Employees/resendemployeewelcomeemail", {
          policyId: dsBenefitCategory.Policy_Id,
          benefitYearId: dsEmployeeYear.BenefitYear_Id,
          benefitCategoryId: dsBenefitCategory.Id,
          employeeId: dsEmployee.Id,
          firstName: dsEmployee.FirstName,
          lastName: dsEmployee.LastName,
          email: dsEmployee.Email,
          phName: dsPolicyHolder.Name,
          hsaBalance: dsEmployeeYear.HSABalance,
        })
        .then((response) => {
          if (response.data.success) {
            toastr.success(response.data.message);
          } else {
            toastr.error("Failed to send email");
          }
        })
        .catch((error) => {
          toastr.error("Failed to send email");
        });
    };

    systemGeneratedBenefitStartDate = () => {
      var systemBenefitStartDate;

      if (this.data.dsEmployee.HireDate) {
        systemBenefitStartDate = this.data.dsEmployee.HireDate;

        if (this.data.dsEmployee.WaitingPeriodMonths) {
          systemBenefitStartDate = moment(this.data.dsEmployee.HireDate)
            .add(this.data.dsEmployee.WaitingPeriodMonths, "months")
            .format("YYYY-MM-DD");
        }
      }

      return systemBenefitStartDate;
    };

    handleReinstateNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ reinstateNote: e.target.value });
    };

    showReinstateModal = () => {
      this.setState({ showReinstateModal: true });
    };

    closeReinstateModal = () => {
      this.setState({ showReinstateModal: false });
    };

    showCustomCategoryModal = (category) => {
      this.setState({
        showCustomCategoryModal: true,
        selectedCustomCategory: category,
        isEligible: category.isActive,
      });
    };

    showAcknowledgmentModal = () => {
      this.setState({
        showCustomCategoryModal: false,
        showAcknowledgmentModal: true,
      });
    };

    hideAcknowledgmentModal = () => {
      this.setState({
        showAcknowledgmentModal: false,
        showCustomCategoryModal: true,
      });
    };

    hideCustomCategoryModal = () => {
      this.setState({
        showAcknowledgmentModal: false,
        showCustomCategoryModal: false,
        selectedCustomCategory: null,
      });
    };

    handleCategoryChange = (index, newEligibility) => {
      const updatedCategories = this.state.editableCategories.map(
        (cat, idx) => {
          if (index === idx) {
            return { ...cat, status: newEligibility };
          }
          return cat;
        }
      );

      this.setState({ editableCategories: updatedCategories });
    };

    saveCustomCategoryEligibility = () => {
      const payload = this.state.editableCategories.map((cat) => ({
        id: cat.id,
        name: cat.customCategoryName,
        isActive: cat.status === "Eligible",
      }));

      axios
        .post("/api/employees/updatecustomcategoryeligibilities", payload)
        .then((response) => {
          if (response.data.success) {
            toastr.success("Eligibility updated successfully.");
            this.setState({ showAcknowledgmentModal: false });
            this.getEmployeeCustomCategories();
          } else {
            toastr.error("Failed to update eligibility.");
          }
        })
        .catch((error) => {
          toastr.error("An error occurred while updating eligibility.");
          console.error(error);
        });
    };

    getFlexSelection = () => {
      fetch(
        "/api/employees/getflexslection?employeeId=" + this.props.employee_id,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            FlexTotalSelected: data.flexTotalSelected,
            HsaSelected: data.hsaSelected,
            LsaSelected: data.lsaSelected,
          });
        })
        .catch((error) => {
          toastr.error("Failed to get flex selection", "Error");
        });
    };

    getTeladoc = () => {
      fetch("/api/employees/getteladoc?employeeId=" + this.props.employee_id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            teladocWaitingPeriod: data.teladocWaitingPeriod,
            teladocStartDate: data.teladocStartDate,
            selectedTeladocWaitingPeriod: TeladocWaitingPeriodMonths.find(
              (option) => option.value == data.teladocWaitingPeriod
            ),
          });
        })
        .catch((error) => {
          toastr.error("Failed to get Teladoc Info", "Error");
        });
    };

    async submitTeladocEdit({ screen }: ActionEvent) {
      const { selectedTeladocWaitingPeriod } = this.state;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          employeeId: this.data.dsEmployee.Id,
          teladocWaitingPeriod: selectedTeladocWaitingPeriod.value,
        }),
      };

      await fetch("/api/employees/editteladoc", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            toastr.success(data.message);
            this.setState({
              showEditTeladocModal: false,
            });
            this.getTeladoc();
            screen.restart();
          } else {
            toastr.error(data.message);
          }
        })
        .catch((error) => {
          toastr.error("Failed to update Teladoc information");
        });
    }

    showEditTeladocModal = () => {
      this.setState({ showEditTeladocModal: true });
    };

    hideEditTeladocModal = () => {
      this.setState({ showEditTeladocModal: false });
    };

    setSelectedTeladocWaitingPeriod = (selectedTeladocWaitingPeriod: any) => {
      var findOption = TeladocWaitingPeriodMonths.find(
        (option) => option.label == selectedTeladocWaitingPeriod
      );
      this.setState({ selectedTeladocWaitingPeriod: findOption });
      this.data.dsEmployee.TeladocWaitingPeriodMonths = findOption.value;
    };

    checkEmployeeNegativeAdjustment = async () => {
      var self = this;
      await axios
        .get(
          "/api/Employees/checknegativeadjustment?employeeId=" +
            this.props.employee_id +
            "&policyId=" +
            this.data.dsBenefitCategory.Policy_Id
        )
        .then((response) => {
          this.setState({
            hasNegativeAdjustments: response.data.hasNegativeAdjustments,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    isPolicyInRunoff = async () => {
      await axios
        .get(
          "/api/Employees/IsPolicyInRunoff?policyId=" +
            this.data.dsBenefitCategory.Policy_Id
        )
        .then((response) => {
          this.setState({
            inRunoff: response.data.inRunoff,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    };
  }
);
