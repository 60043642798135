import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Column,
  Panel,
  Display,
  Line,
  Card,
} from "ktm-ui";
import moment from "moment";
import toastr from "toastr";
import CustomToolTip from "components/CustomToolTip";

interface IdsBenefit {
  Id: string;
  FlexLaunchDate: string;
  FlexReminderDate: string;
  FlexLastDayReminderDate: string;
  FlexSelectionDeadline: string;
  IsFlexLaunchEmailSent: boolean;
  IsFlexReminderEmailSent: boolean;
  IsFlexLastDayReminderEmailSent: boolean;
  IsFlexSelectionDone: boolean;
  EnableFlexEmails: boolean;
}

export default KTMScreen(
  { name: "enroll.employee-benefits.edit-flex-dates" },
  class extends React.Component<{ employeeBenefit_id: string }> {
    data: {
      dsBenefit: IdsBenefit;
    };

    state = {
      flexLaunchDate: null,
      flexReminderDate: null,
      flexLastDayReminderDate: null,
      flexSelectionDeadline: null,
      disableFlexEmails: false,
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@employeeBenefit_id": this.props.employeeBenefit_id,
      });
      screen.title = "Edit employee benefit limits";

      this.setState({
        flexLaunchDate: this.data.dsBenefit.FlexLaunchDate,
        flexReminderDate: this.data.dsBenefit.FlexReminderDate,
        flexLastDayReminderDate: this.data.dsBenefit.FlexLastDayReminderDate,
        flexSelectionDeadline: this.data.dsBenefit.FlexSelectionDeadline,
        disableFlexEmails: !this.data.dsBenefit.EnableFlexEmails
      });
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label={"Edit Flex Dates"} />;
    }

    footer({ field, draw, action }: RenderEvent) {
      var isDisabled =
        this.data.dsBenefit.FlexLaunchDate === null ||
        this.data.dsBenefit.FlexSelectionDeadline === null
          ? true
          : false;

      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
            disable={isDisabled}
          />
        </Dialog.Footer>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.flex)}
          </Column>
        </Panel>
      );
    }

    flex({ field }: RenderEvent) {
      const currentDate = moment().format("YYYY-MM-DD");
      const { flexLaunchDate, flexReminderDate, flexLastDayReminderDate } = this.state;
      
      const disableEmailsContent = (
        <div className="RT-Checkbox">
            <label className="RT-Checkbox__inner">
              <input
                type="checkbox"
                className="RT-Checkbox__input"
                checked={this.state.disableFlexEmails}
                onChange={this.handleCheckboxChange}
              />
              <span className="RT-Checkbox__faux">
                <svg viewBox="0 0 52 52">
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                </svg>
              </span>
              <span className="RT-Checkbox__label">
                Disable Selection Emails
                <CustomToolTip
                  header="Disable Selection Emails"
                  bodyContent="Checking this box disables Flex Selection emails from going out to employees, in order for Ops users to allocate on their behalf."
                />
              </span>
            </label>
          </div>
      )
      if (this.data.dsBenefit.IsFlexSelectionDone) {
          return (
              <Card>
                  <Line>
                      <Display field={field("FlexLaunchDate")} />
                      <Display field={field("FlexSelectionDeadline")} />
                  </Line>
                  <Line>
                    {disableEmailsContent}
                  </Line>
              </Card>
          );
      }
      
      return (
          <Card>
              <Line>
                  {(!this.data.dsBenefit.IsFlexLaunchEmailSent || moment(flexLaunchDate).format("YYYY-MM-DD") > currentDate) ? (
                  <Display field={field("FlexLaunchDate")} />
                  ) : <></>}
                  {(!this.data.dsBenefit.IsFlexReminderEmailSent || moment(flexReminderDate).format("YYYY-MM-DD") > currentDate) ? (
                  <Display field={field("FlexReminderDate")} />
                  ) : <></>}
              </Line>
              <Line>
                  {(!this.data.dsBenefit.IsFlexLastDayReminderEmailSent || moment(flexLastDayReminderDate).format("YYYY-MM-DD") > currentDate) ? (
                  <Display field={field("FlexLastDayReminderDate")} />
                  ) : <></>}
                  <Display field={field("FlexSelectionDeadline")} />
              </Line>
              <Line>
                {disableEmailsContent}
              </Line>
          </Card>
      );
    }

    handleCheckboxChange = (event) => {
      const isChecked = event.target.checked;
      this.setState({ disableFlexEmails: isChecked });
  
      this.data.dsBenefit.EnableFlexEmails = !isChecked;
    };

    async submit({ screen }: ActionEvent) {
      const currentDate = moment().format("YYYY-MM-DD"); 
      const selectionDeadline = moment(this.state.flexSelectionDeadline).format("YYYY-MM-DD");
      if(currentDate < selectionDeadline)
      {
        const futureDate = moment().add(1, 'days').format("YYYY-MM-DD");
        const {
          FlexLaunchDate,
          FlexReminderDate,
          FlexLastDayReminderDate,
          FlexSelectionDeadline,
          IsFlexLaunchEmailSent,
          IsFlexReminderEmailSent,
          IsFlexLastDayReminderEmailSent,
          IsFlexSelectionDone,
        } = this.data.dsBenefit;
    
        if(!IsFlexSelectionDone)
        {
            if(!IsFlexLaunchEmailSent)
            {
              if(moment(FlexLaunchDate).isBefore(futureDate))
              {
                toastr.error("Flex Launch Date should be greater than today's date");
                return;
              }
              if(moment(FlexLaunchDate).isAfter(FlexReminderDate))
              {
                toastr.error("Flex Launch Date should be less than Flex Reminder Date");
                return;
              }
              if(moment(FlexLaunchDate).isAfter(FlexLastDayReminderDate))
              {
                toastr.error("Flex Launch Date should be less than Flex Last Day Reminder Date");
                return;
              }
              if(moment(FlexLaunchDate).isAfter(FlexSelectionDeadline))
              {
                toastr.error("Flex Launch Date should be less than Flex Selection Deadline");
                return;
              }
            }
            if(!IsFlexReminderEmailSent)
            {
              if(moment(FlexReminderDate).isBefore(futureDate))
              {
                toastr.error("Flex Reminder Date should be greater than today's date");
                return;
              }
              if(moment(FlexReminderDate).isSame(FlexLaunchDate))
              {
                toastr.error("Flex Reminder Date should not be same as Flex Launch Date");
                return;
              }
              if(moment(FlexReminderDate).isAfter(FlexLastDayReminderDate))
              {
                toastr.error("Flex Reminder Date should be less than Flex Last Day Reminder Date");
                return;
              }
              if(moment(FlexReminderDate).isAfter(FlexSelectionDeadline))
              {
                toastr.error("Flex Reminder Date should be less than Flex Selection Deadline");
                return;
              }
              if(moment(FlexReminderDate).isSame(FlexLastDayReminderDate))
              {
                toastr.error("Flex Reminder Date should not be same as Flex Last Day Reminder Date");
                return;
              }
            }
            if(!IsFlexLastDayReminderEmailSent)
            {
              if(moment(FlexLastDayReminderDate).isBefore(futureDate))
              {
                toastr.error("Flex Last Day Reminder Date should be greater than today's date");
                return;
              }
              if(moment(FlexLastDayReminderDate).isBefore(FlexLaunchDate))
              {
                toastr.error("Flex Last Day Reminder Date should be greater than Flex Launch Date");
                return;
              }
              if(moment(FlexLastDayReminderDate).isSame(FlexLaunchDate))
              {
                toastr.error("Flex Last Day Reminder Date should not be same as Flex Launch Date");
                return;
              }
              if(moment(FlexLastDayReminderDate).isAfter(FlexSelectionDeadline))
              {
                toastr.error("Flex Last Day Reminder Date should be less than Flex Selection Deadline");
                return;
              }
            }
        }
        if(moment(FlexLaunchDate).isBefore(futureDate))
        {
          toastr.error("Flex Launch Date should be greater than today's date");
          return;
        }
        if(moment(FlexSelectionDeadline).isBefore(futureDate))
        {
          toastr.error("Flex Selection Deadline should be greater than today's date");
          return;
        }
        if(moment(FlexLaunchDate).isAfter(FlexSelectionDeadline))
        {
          toastr.error("Flex Launch Date should be less than Flex Selection Deadline");
          return;
        }
      }

      await KTMApi.action(screen, "submit", {
        "@dsEmployee.FlexLaunchDate": this.data.dsBenefit.FlexLaunchDate,
        "@dsEmployee.FlexSelectionDeadline": this.data.dsBenefit.FlexSelectionDeadline,
        "@dsEmployee.FlexReminderDate": this.data.dsBenefit.FlexReminderDate,
        "@dsEmployee.FlexLastDayReminderDate": this.data.dsBenefit.FlexLastDayReminderDate,
        "@dsEmployee.EnableFlexEmails": this.data.dsBenefit.EnableFlexEmails,
      });
      
      screen.close({ continue: true });
    }
  }
);
