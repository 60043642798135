import { useState } from "react";
import {Line, Card} from "ktm-ui";
import CustomPagination from "components/CustomPagination";

const BenefitYearHistory = ({benefitYears}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    
    const currentRecords = benefitYears.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(benefitYears.length / recordsPerPage)

    let rows = currentRecords.map((data, i) => {
        var status = "";
        if (data.status == "OPEN") {
          status = "Open For Claims";
        } else if (data.status == "CLOSED") {
          status = "Closed";
        } else if (data.status == "CampaignOpen") {
          status = "Selections In Progress";
        } else {
          status = "Run-Off";
        }
        return (
          <tr key={i}>
            <td style={{ border: "1px solid black", padding: "5px" }}>
              {data.startDate}
            </td>
            <td style={{ border: "1px solid black", padding: "5px" }}>
              {data.endDate}
            </td>
            <td style={{ border: "1px solid black", padding: "5px" }}>
              {data.runOffClosingDate}
            </td>
            <td style={{ border: "1px solid black", padding: "5px" }}>
              {status}
            </td>
          </tr>
        );
      });

    return (
        <Card label="History">
          <Line>
            <div>
              This table shows the dates for each benefit period. During the
              "Runoff" period, claims for the elapsed period will still be
              processed.
            </div>
          </Line>
          <Line>
            <table>
              <thead style={{ border: "1px solid black" }}>
                <tr style={{ border: "1px solid black" }}>
                  <th style={{ border: "1px solid black", padding: "5px" }}>
                    Start Date
                  </th>
                  <th style={{ border: "1px solid black", padding: "5px" }}>
                    End Date
                  </th>
                  <th style={{ border: "1px solid black", padding: "5px" }}>
                    Runoff Ends
                  </th>
                  <th style={{ border: "1px solid black", padding: "5px" }}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody style={{ border: "1px solid black" }}>{rows}</tbody>
            </table>
          </Line>
          <Line>
            <div className="pagination-container" style={{marginLeft:"70px"}}>
                    <div className="pagination-info">
                        <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {benefitYears.length} entries</span>
                    </div>
                    <CustomPagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                </div>
          </Line>
        </Card>
    )
}

export default BenefitYearHistory