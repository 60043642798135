import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Card} from 'ktm-ui';

interface IdsBenCat {
    Id:string;
    Name:string;
    Description:string;
    Policy_Id:string;
};

export default KTMScreen({name:"planadmin.bencat.new"}, class extends React.Component<{policy_id:string}> {

    data:{
        dsBenCat:IdsBenCat;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "New Benefit Category";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="New Benefit Category"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("Name")}/>
                <Display field={field("Description")}/>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsBenCat.Name":this.data.dsBenCat.Name,
            "@dsBenCat.Description":this.data.dsBenCat.Description,
            "@policy_id":this.props.policy_id,
        });
        screen.close({"continue":true});
    }

});
