import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { DataGrid, IDataGridLayout, EmptyState } from "ktm-ui";
import { Button } from "react-bootstrap";
import Edit_expense_typeScreen from "./Edit_expense_type";
import Remove_expense_typeScreen from "./Remove_expense_type";
import Add_expense_typesScreen from "./Add_expense_types";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface IdsExpenseTypes {
  Id: string;
  Policy_Id: string;
  Limit_Id: string;
  ExpenseType_Id: string;
  CoverageRate: number;
  AccountType: string;
  Code: string;
  ShortName: string;
  Description: string;
}

export default KTMScreen(
  { name: "planadmin.ben-limit.expense-types" },
  class extends React.Component<{ policy_id: string; limit_id: string, bencat_name:string }> {
    data: {
      dsExpenseTypes: IdsExpenseTypes[];
    };

    hasHSA(): boolean {
      const { dsExpenseTypes } = this.data;
      return dsExpenseTypes.some(expense => expense.AccountType === "HSA");
    }
  
    hasLSA(): boolean {
        const { dsExpenseTypes } = this.data;
        return dsExpenseTypes.some(expense => expense.AccountType === "LSA");
    }

    generatePDFDocumentForHSA = () => {
      const { dsExpenseTypes } = this.data;
  
      const tableBody = [];
      dsExpenseTypes
          .filter(expense => expense.AccountType === "HSA" && expense.Code !== "OTC")
          .forEach(expense => {
              tableBody.push([{ 
                  text: expense.ShortName, 
                  fillColor: '#ADD8E6',
                  colSpan: 2,
                  bold: true
              }, {}]); 
              
              const descriptionLines = expense.Description.split('\\n').map(line => line.trim());

              tableBody.push([{ 
                  stack: descriptionLines,
                  colSpan: 2,
                  fontSize: 12
              }, {}]);
          });

  
      const introductoryText = [
          { text: 'The Health Spending Account', style: 'header' },
          'Use this tax-free account to get coverage for a wide range of health and dental expenses.',
          { text: 'Note: If you already have coverage for these items through a traditional insurance group plan, always use them first. Your health spending account can be used to pay for any portions the group plan did not pay, deductibles/co-payments and items your group plan doesn\'t cover at all. In your submission to us include their "Explanation of Benefits," the detailed document that confirms how much the insurance carrier has paid towards an expense (even if it is 0). Online printout copies are acceptable.', style: 'note'},
          { text: 'If you don’t have a traditional insurance group plan, submit to National HealthClaim directly.', style: 'subnote'},
          { text: 'What can I claim?', style: 'subheader' },
          { text: 'See the coverage charts below.', style: 'chartnote'},
          { text: 'Expenses can be from inside or outside Canada. National HealthClaim is as diligent as possible in compiling and updating this list. However, National HealthClaim does not guarantee that this information is up to date. If there is a discrepancy between this list and the list of Canada Revenue Agency (CRA) eligible medical expenses, the CRA list will always take precedence.', style: 'chartnote'},
      ];
  
      return {
          content: [
              ...introductoryText,
              {
                table: {
                  headerRows: 0,
                  widths: ['*', '*'],
                  body: tableBody
                }
              }
          ],
          styles: {
              header: {
                  fontSize: 18,
                  bold: true,
                  alignment: 'center',
                  margin: [0, 0, 0, 20]
              },
              note: {
                  margin: [40, 10, 0, 10],
                  fontSize: 12,
              },
              subnote: {
                  margin: [40, 0, 0, 10],
                  fontSize: 12,
              },
              subheader: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 10, 0, 10]
              },
              chartnote: {
                  margin: [0, 0, 0, 10],
                  fontSize: 12,
              }
          }
      };
    }
  

    generatePDFDocumentForLSA = () => {
      const { dsExpenseTypes } = this.data;
  
      const tableBody = [];
      dsExpenseTypes
        .filter(expense => expense.AccountType === "LSA")
        .sort((a, b) => {
          // Special condition: Make "AA - PAY AS SUBMITTED" come last
          if (a.Code === "*PAY AS SUBMITTED" && b.Code !== "*PAY AS SUBMITTED") {
            return 1;
          }
          if (b.Code === "*PAY AS SUBMITTED" && a.Code !== "*PAY AS SUBMITTED") {
            return -1;
          }
          // Otherwise, sort by ShortName
          return a.ShortName.localeCompare(b.ShortName);
        })
        .forEach(expense => {
          tableBody.push([{ 
              text: expense.ShortName, 
              fillColor: '#90EE90',
              colSpan: 2,
              bold: true
          }, {}]); 
          
          const descriptionLines = expense.Description.split('\\n').map(line => line.trim());

          tableBody.push([{ 
              stack: descriptionLines,
              colSpan: 2,
              fontSize: 12
          }, {}]);
      });

      const introductoryText = [
        { text: 'The Lifestyle Spending Account', style: 'header' },
      ];

      return {
          content: [
              ...introductoryText,
              {
                  table: {
                      headerRows: 1,
                      widths: ['*', '*'],
                      body: tableBody
                  }
              }
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              alignment: 'center',
              margin: [0, 0, 0, 20]
            }
          },
          footer: (currentPage, pageCount) => {
              return {
                text: `Page ${currentPage} of ${pageCount}`,
                alignment: 'center',
                margin: [0, 10, 0, 0], // Adjust margins as needed
                fontSize: 10
            };
          }
      };
    }

    downloadHSA = () => {
      let documentDefinition = this.generatePDFDocumentForHSA();
      let fileName = `${this.props.bencat_name} HSA Expense Types.pdf`;
      pdfMake.createPdf(documentDefinition).download(fileName);
    }

    downloadLSA = () => {
      let documentDefinition = this.generatePDFDocumentForLSA();
      let fileName = `${this.props.bencat_name} LSA Expense Types.pdf`;
      pdfMake.createPdf(documentDefinition).download(fileName);
    }

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsExpenseTypes";
      await KTMApi.start(screen, {
        "@policy_id": this.props.policy_id,
        "@limit_id": this.props.limit_id,
      });
      screen.title = "Expense types";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <div style={{display:"flex", flexDirection: "column", padding: "20px 0px 20px 0px"}}>
          {draw(this.default_view)}
          <div>
            {this.hasHSA() && <Button variant="primary" onClick={this.downloadHSA}>Download HSA</Button>}
            {this.hasLSA() && <Button variant="primary" onClick={this.downloadLSA} style={this.hasHSA()?{marginLeft:20}:null}>Download LSA</Button>}
          </div>
        </div>
      );
    }

    default_view({ field, draw, action, collection }: RenderEvent) {
        this.data.dsExpenseTypes = this.data.dsExpenseTypes.map(x => {
            if (x.Code === "OTC") {
                x.CoverageRate = null;
            }
            return x;
        });
      let actions = (
        <>
          {/* <Button
            action={action(this.add)}
            label="Add"
            style="add"
          /> */}
        </>
      );
      let layout: IDataGridLayout = {
        columns: [
          { name: "AccountType", label: "Account Type", type: "character" },
          { name: "Code", label: "Code", type: "character" },
          { name: "CoverageRate", label: "Coverage (%)", type: "decimal" },
          {
            name: "ShortName",
            label: "Short Name",
            type: "character",
            width: 450,
          },
        //   { action: "edit", label: "Edit", width: 80 },
        //   { action: "remove", label: "Remove", width: 80 },
        ],
        sort: [{ name: "AccountType" }, { name: "ShortName" }],
      };
      return (
        <DataGrid
          collection={collection("@dsExpenseTypes")}
          layout={layout}
          actions={actions}
        />
      );
    }

    empty_state({ field, draw, action }: RenderEvent) {
      return (
        <EmptyState label="No expenses types have been added yet." icon="bill">
          {/* <Button
            action={action(this.add)}
            label="Add"
            style="primary"
          /> */}
        </EmptyState>
      );
    }

    async edit({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_expense_typeScreen
          expense_id={screen.getValue("@dsExpenseTypes.Id")}
          policy_id={screen.getValue("@dsExpenseTypes.Policy_Id")}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async remove({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_expense_typeScreen
          policy_id={screen.getValue("@dsExpenseTypes.Policy_Id")}
          expense_id={screen.getValue("@dsExpenseTypes.Id")}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async add({ screen }: ActionEvent) {
      await screen.openDialog(
        <Add_expense_typesScreen
          policy_id={this.props.policy_id}
          limit_id={this.props.limit_id}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async refresh_data({ screen }: ActionEvent) {
      await screen.restart();
    }
  }
);
