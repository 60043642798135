import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import axios from "axios";
import toastr from "toastr";

const BenefitEnhancementsRequestLSA = (props) => {
    const [requests, setRequests] = useState([{ id: Date.now(), type: '', amount: '', description: '' }]);

    const isSubmitDisabled = !requests.some(
        (request) =>
            request.type.trim() !== '' &&
            request.amount.trim() !== '' &&
            request.description.trim() !== ''
    );

    const handleAddRequest = () => {
        setRequests([...requests, { id: Date.now(), type: '', amount: '', description: '' }]);
    };

    const handleChange = (id, field, value) => {
        setRequests(requests.map(req => {
            if (req.id === id) {
                const updatedReq = { ...req, [field]: value };

                if (field === 'type') {
                    updatedReq.description = getDefaultDescription(value);
                }

                return updatedReq;
            }
            return req;
        }));
    };

    const getDefaultDescription = (type) => {
        switch (type) {
            case 'Workboots':
                return 'Annual allowance for all types of work-related CSA-certified safety workboots';
            case 'Tools & Equipment':
                return 'Work-related tools and equipment to enhance productivity';
            case 'Pet Care & Services':
                return 'Expenses related to pet care and veterinary services, boarding, pet sitting, obedience training';
            case 'Fitness Equipment':
                return 'Exercise equipment (non-apparel), kayaks, e-bikes, and other equipment required to participate in a sport';
            case 'Travel & Vacation':
                return 'Flights, hotels, Airbnb, hostels, online vacation packages, all-inclusive holidays and airport parking';
            case 'Infant Equipment & Furniture':
                return 'Cribs, strollers, high chairs, car seats, baby monitors, breast pumps (excludes food, clothing, diapers, toys, decor)';
            case 'Education & Personal Development':
                return 'Tuition and fees for university/college or continuing ed, driver\'s ed courses, seminars and conferences';
            case 'Health Related Services':
                return 'Alternative health & wellness (herbalists, shiatsu massage, reiki, light therapy, etc), health-related mobile apps';
            case 'Weight Loss Programs & Products':
                return 'Programs and non-prescription products for weight management';
            case 'Spa Services':
                return 'Services related to relaxation and wellbeing (Manicure/pedicure, facials, etc.)';
            default:
                return '';
        }
    };

    const handleDeleteRequest = (id) => {
        setRequests(requests.filter(req => req.id !== id));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post("/api/BenefitCategories/sendenhancementsrequest", {
            accountType: "LSA",
            companyName: props.companyName,
            bencatName: props.bencatName,
            bencatRequests: requests,
            policyId: props.policyId
        }).then((response) => {
            if (response.data.success) {
                toastr.success("Your request has been submitted successfully!");
                setRequests([{ id: Date.now(), type: '', amount: '', description: '' }]);
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h1>Enhanced Benefits Request (LSA+)</h1>
            <img src="/plus-lsa.png" style={{float: "right", marginLeft: "10px"}}/>
            <Form.Text>
                <br/>
                <strong>It's easy to add a Workboot Program, Educational & Personal Development, and more.</strong>
                <br/><br/>
                Enhanced Benefits are additional credits added on top of your existing HSA and/or LSA benefits to
                cover specific expense types your employees need.
                <br/>
                For example, if a category of employees needs extra physiotherapy coverage due to the demands of their
                work, you can add this coverage on top of their base benefits to address that specific need.
                <br/>
                These enhancements allow you to tailor your benefit credits to meet the diverse needs of your staff.
                <br/>
                Each Enhanced Benefit is category-specific, and you can request up to six per HSA and LSA.
                <br/>
                <a
                    href={"https://nhcfilestorage.blob.core.windows.net/docfiles/Plus-Enhanced-Benefits_LSA.pdf"}
                    target="_blank"
                    style={{fontStyle: 'italic', color: "#4987ef"}}
                >Click here to view the full LSA+ Enhanced Benefits Catalogue.</a>
                <br/><br/>
                <strong>Use the tool below to request your Enhanced Benefits. After clicking “Submit for Review,” our
                    team will assess your request and activate your Enhanced Benefits, or contact you if further
                    clarification is needed.</strong>
            </Form.Text>

            <br/><br/>
            <Form.Text className="text-muted mt-2" style={{fontSize: 11, fontStyle: "italic"}}>Enhanced Benefits
                cannot be carried forward, and are automatically renewed annually unless removed by PA
                request.</Form.Text>

            {requests.map((request, index) => (
                <Row key={request.id} className="mb-3">
                    <Col md={3}>
                        <Form.Select
                            value={request.type}
                            onChange={(e) => handleChange(request.id, 'type', e.target.value)}
                        >
                            <option>Select...</option>
                            <option value="Workboots">Workboots</option>
                            <option value="Tools & Equipment">Tools & Equipment</option>
                            <option value="Pet Care & Services">Pet Care & Services</option>
                            <option value="Fitness Equipment">Fitness Equipment</option>
                            <option value="Travel & Vacation">Travel & Vacation</option>
                            <option value="Infant Equipment & Furniture">Infant Equipment & Furniture</option>
                            <option value="Education & Personal Development">Education & Personal Development
                            </option>
                            <option value="Health Related Services">Health Related Services</option>
                            <option value="Weight Loss Programs & Products">Weight Loss Programs & Products</option>
                            <option value="Spa Services">Spa Services</option>
                        </Form.Select>
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            type="number"
                            placeholder="Amount"
                            value={request.amount}
                            onChange={(e) => handleChange(request.id, 'amount', e.target.value)}
                        />
                    </Col>
                    <Col md={4}>
                        <Form.Control
                            type="text"
                            placeholder="Description"
                            value={request.description}
                            onChange={(e) => handleChange(request.id, 'description', e.target.value)}
                        />
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                        {requests.length < 6 && (
                            <Button variant="primary" onClick={handleAddRequest}>
                                +
                            </Button>)}
                        {requests.length > 1 && (
                            <Button variant="danger" onClick={() => handleDeleteRequest(request.id)}>
                                -
                            </Button>)}
                    </Col>
                </Row>
            ))}
            <Button style={{backgroundColor: '#059454'}} type="submit" disabled={isSubmitDisabled}>
                Submit for Review
            </Button>
        </Form>
    );
};

export default BenefitEnhancementsRequestLSA;
